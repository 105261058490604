import React, { Component } from 'react';
import { Button, Icon, Divider, Skeleton } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import API from '../../../../../api';
import './index.css'


class PaymentScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payment: {id: '', booking_type: '', booking_id: '', status: '', order_id: '', reason: '', message: '', receipt_no: '', receipt_url: '', invoice_url: '' }
    }
  }

  componentDidMount() {
    const that = this;

    const params = this.props.location.search;
    let payment_id = this.props.location.pathname.match(/\d+/);
    let url = './api/v1/payments/' + payment_id;
    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        if(response.data.error){
          that.setState({
            loading: false,
            payment: {status: "Not Found"}
          })
        } else {
          that.setState({
            loading: false,
            payment: {id: response.data.id, booking_type: response.data.booking_type, booking_id: response.data.booking_id, order_id: response.data.order_id, result: response.data.result, reason: response.data.reason, message: response.data.message, receipt_no: response.data.receipt_no, receipt_url: response.data.receipt_url, invoice_url: response.data.invoice_url }
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    const {loading, payment, booking_id, order_id, result} = this.state;

    return (
      loading ? <Skeleton active /> :
        <div id="paymentScreen">
          {
            payment.result === "paid" ?
              <React.Fragment>
                <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                <h1>Payment Successful</h1>
                
                <div>
                  <h2>Thank you for your payment.</h2>
                  <p id="font">
                    <strong>Your Booking ID: {payment.order_id} </strong><br/>
                    <strong>{payment.message}</strong>
                  </p>
                </div>    
                <Divider/>
              </React.Fragment>
            : payment.result === "pending" ?
            <React.Fragment>
              <Icon type="clock-circle" theme="twoTone" twoToneColor="#FF8700" />
              <h1>Payment is in progress.</h1>
              <h2>
                Please refresh the page to update the payment status after 15 minutes.
              </h2>
              <p id="font">
                <strong>Your Booking ID: {payment.order_id} </strong>
              </p>

              <Divider/>
            </React.Fragment>
            : payment.result === "failed" ?
            <React.Fragment>
              <Icon type="close-circle" theme="twoTone" twoToneColor="#ce271b" />
              <h1>Payment Failed</h1>
              <p id="font">
              <h2>
                {
                  this.state.payment.reason == '' ? "" : this.state.payment.reason
                }
              </h2>

              </p>
              <strong>Please repay at travelb2b.my.</strong>
              <strong> Thank you. </strong>
              <Divider/>
            </React.Fragment> : 
            <React.Fragment>
              <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#2178FF" />
              <h1>No Payment Found </h1>
              <h2>
                Apologies, the payment that you requested could not be found. 
              </h2>

              <Divider/>
            </React.Fragment>

          }
            
          {/*<React.Fragment>
            <Button>
              <a href={window.location.origin}>Home Page</a>
            </Button>
            <span>&emsp;</span>       
            <p>Having trouble? <a href={window.location.origin}>Contact us</a></p>
          </React.Fragment>  */}
      </div>

    );
  }
}
export default PaymentScreen