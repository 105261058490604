import React, { Component } from 'react';
import { Menu, Icon, Select, Dropdown, Divider, Badge } from 'antd';
import './index.css'
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';
import API from '../../../../api';

const { Option } = Select;

function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}


class ShowMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 'flight',
      menuKey: this.props.menuKey,
      user: { username: '', credits: 0, access_flight: false, access_wifi: true, access_series: false, access_activity: false, access_hotel: false, access_land_tour: false },
    }
  }

  componentDidMount() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          user: { username: response.data.username, credits: response.data.credits, access_flight: response.data.access_flight, access_wifi: response.data.access_wifi, access_series: response.data.access_series, access_activity: response.data.access_activity, access_hotel: response.data.access_hotel, access_land_tour: response.data.access_land_tour, access_admin: response.data.access_admin },
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }

  static propTypes = {
    location: PropTypes.object.isRequired
  }

  goBack = () => {
    this.props.history.go(-1);
  }

  render() {
    const { location } = this.props;
    const menu = (
      this.state.user.username !== "ktic" ?
        (<Menu>
          <Menu.Item disabled={true}>Credit: {this.state.user.credits}</Menu.Item>
          <Menu.Divider></Menu.Divider>
          {
            this.state.user.access_series ? 
              (<Menu.Item><Link to="/bookings/series"><b>My Bookings</b></Link></Menu.Item>) :
            this.state.user.access_wifi ? 
              (<Menu.Item><Link to="/bookings/wifis"><b>My Bookings</b></Link></Menu.Item>) : 
            this.state.user.access_series ? 
              (<Menu.Item><Link to="/bookings/series"><b>My Bookings</b></Link></Menu.Item>) :
            this.state.user.access_wifi ? 
              (<Menu.Item><Link to="/bookings/wifis"><b>My Bookings</b></Link></Menu.Item>) : 
            this.state.user.access_flight ? 
              (<Menu.Item><Link to="/bookings/flight"><b>My Bookings</b></Link></Menu.Item>) :
            this.state.user.access_activity ? 
              (<Menu.Item><Link to="/bookings/activity"><b>My Bookings</b></Link></Menu.Item>) :
            this.state.user.access_land_tour ?
              (<Menu.Item><Link to="/bookings/land_tours"><b>My Bookings</b></Link></Menu.Item>) : ""
          }
          <Menu.Item>
            <Link to="/bookings/due_payments">Due Payments</Link>
          </Menu.Item>
          {
            this.state.user.access_admin ?
              <Menu.Item><a href="./admin/dashboard">Admin Dashboard</a></Menu.Item>
            : null
          }
          {
            homepage_new.suka === 'false' ?
              <Menu.Item><Link to="/users/credit_usage">Credit Usage</Link></Menu.Item>
            : null
          }
          <Menu.Item><a href={homepage_new.pdpa} target="_blank">PDPA</a></Menu.Item>
          <Menu.Item><Link to="/users/profile">Edit Profile</Link></Menu.Item>
          <Menu.Item><Link to="/agency/profile">Edit Agency Profile</Link></Menu.Item>
          <Menu.Item><a href="./user/edit">Change Password</a></Menu.Item>
          {
            homepage_new.suka === 'false' ?
              <Menu.Item><a href={homepage_new.memo} target="_blank">Memo</a></Menu.Item>
            : null
          }
          <Menu.Item><a href="./users/sign_out" >Logout</a></Menu.Item>
        </Menu>)
      : (
          <Menu>
            <Menu.Item><a href={homepage_new.pdpa} target="_blank">PDPA</a></Menu.Item>
          </Menu>
        )
    );

    return (
      <div id="ShowMenu">
        {/* <Menu defaultSelectedKeys={this.state.menuKey} selectedKeys={[location.pathname]} mode="horizontal">
          <Menu.Item key="flights">
            <Link to="/flights/:id"><Icon type="rocket" />Flight</Link>
          </Menu.Item>
          <Menu.Item key="hotels" >
            <Link to="/hotels/:id"><Icon type="shop" />Hotel</Link>
          </Menu.Item>
          <Menu.Item key="activity">
            <Link to="/activity/:id"><Icon type="heart" />Activity</Link>
          </Menu.Item>
          <Menu.Item key="wifi">
            <Link to="/wifi/:id"><Icon type="wifi" />Wifi</Link>
          </Menu.Item>

          <Menu.Item className="drop_warp">
            <Dropdown overlay={menu} trigger={['click']}>
              <a className="ant-dropdown-link drop_btn" href="#">
                Jason <Icon type="down" />
              </a>
            </Dropdown>
          </Menu.Item>
        </Menu> */}
        <Menu defaultSelectedKeys={this.state.menuKey} selectedKeys={[location.pathname]} mode="horizontal">
          <Menu.Item key="back" onClick={this.goBack}>
            <FontAwesomeIcon icon='angle-left' size="2x" className="back_icon" />Back
          </Menu.Item>
    
          {
            is_logged_in ? (
              <Menu.Item className="drop_warp">
                <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Link to="/notifications">
                  <Badge count={this.state.unread_notifications_count}>
                    <Icon type="bell" theme="twoTone" className="bell-icon" />
                  </Badge>
                </Link>
              </div>
              <Divider type="vertical" />
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="drop_menu"
              >
                <a className="ant-dropdown-link" href="#">
                  {this.state.user.username} <Icon type="down" />
                </a>
              </Dropdown>
            </div>
              </Menu.Item>
            ) : (
              <>
              </>
            )
          }
        </Menu>


      </div >
    );
  }
}

export default withRouter(ShowMenu);
