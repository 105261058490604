import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { Divider, Spin, Button, List, Icon, Modal, Typography, Tag, Skeleton, Empty } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactMarkdown from 'react-markdown/with-html';
import './index.css';
import { withRouter, Link } from 'react-router-dom';
import gd from '../../../../../images/gd.png';
import gd_domestic from '../../../../../images/gd_domestic.png';
import gd_signature from '../../../../../images/gd_signature.png';
import cruise from '../../../../../images/cruise.png';
import fly_cruise from '../../../../../images/fly_cruise.png';
import fs from '../../../../../images/fs.png';
import premium from '../../../../../images/premium.png';
import qs from 'qs';
import CopyToClipboardTooltip from '../../../copyToClipboardTooltip';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const { Paragraph } = Typography;

class RightPanel extends Component {
   constructor(props) {
      super(props);
      this.state = {
         departureDates: null,
         loadingMore: false,
         hasMore: true,
         infoModalVisible: false,
         itineraryModalVisible: false,
         modalData: { title: '', other_caption: '', description: '', category: '', location: '', type: '', departdate: '', itinerary_url: '' },
         numPages: null,
         page_width: 1000,
         page: 1,
      }
   }

   onDocumentLoadSuccess = async (pdf_doc) => {
      const page = await pdf_doc.getPage(1);
      console.log(page.view[2]); // Width
      console.log(page.view[3]); // Height
      this.setState({
        numPages: pdf_doc["_pdfInfo"]["numPages"],
        page_width: page.view[2] + 50,
      });
    }

   showModal = (id, type) => {
    const modalData = this.props.detail.find(elm => {
      return elm.id === id
    })

    this.setState({
      modalData: {
        title: modalData.caption,
        other_caption: modalData.other_caption,
        description: modalData.description,
        category: modalData.category,
        location: modalData.country,
        type: modalData.code,
        departdate: modalData.departure_date.map((departdate) => <Tag style={{ margin: '3px 6px 3px 0' }} color="orange">{departdate}</Tag>),
        itinerary_url: modalData.file_url,
        file_copy_url: modalData.file_copy_url,
      }
    });

    if (type == "info_modal") {
      this.setState({
        infoModalVisible: true,
      });
    }
    else if (type == "itinerary_modal") {
      this.setState({
        itineraryModalVisible: true,
      });
    }
   };

   onCloseModal = (modal) => {
    this.setState({
      infoModalVisible: false,
      itineraryModalVisible: false,
    });
  };


  loadMoreData = () => {
    this.setState({
      loadingMore: true,
    });

    if (this.props.limit >= this.props.dataLength) {
      this.setState({
        loadingMore: false,
        hasMore: false
      });
    } else {
      this.setState({
        page: this.props.page + 1,
        loadingMore: false,
      }, () => { this.props.itineraryFetch(this.state.page) });
      // setTimeout(() => {
      //   this.setState({
      //     limit: this.props.limit + 2,
      //     loadingMore: false,
      //   });
      // }, 1000);
    }
  }

   applyGuaranteedBadgeColor(date, guaranteedDates = [], almostGuaranteedDates = []) {
      if(guaranteedDates.includes(date)) {
         return 'green'
      }
      if(almostGuaranteedDates.includes(date)) {
         return 'cyan'
      }
      return 'orange'
   }

   setDepatureDates(itineraries) {
      const departureDatesObject = {}

      itineraries.forEach((itinerary) => {
         let dateDate = itinerary.departure_date && itinerary.departure_date.map((date) => (
            { value: date, class: this.applyGuaranteedBadgeColor(date, itinerary.guranteed_departure_dates, itinerary.almost_guaranteed_departure_dates) }
         ))
         departureDatesObject[itinerary.id] = dateDate
      })
      this.setState({
         departureDates: departureDatesObject
      })
   }

   componentDidUpdate(prevProps) {
      if(this.props.detail !== prevProps.detail) {
         this.setDepatureDates(this.props.detail)
      }
      console.log("Check limit count ---> ", this.props.limit)
      console.log("Check dataLength count ---> ", this.props.dataLength)
   }

   render() {
      const dataDetail = this.props.detail;
      const dataLength = this.props.dataLength;
      const { loadingMore, hasMore, infoModalVisible, modalData, numPages, page_width, departureDates } = this.state;

      return (
         <div id="RightPanel">
            {
               dataLength === 0 ? (
                  <div className="empty">
                     {
                        this.props.loading ? (
                           <Skeleton active avatar paragraph={{ rows: 4 }} />
                        ) : (
                              <Empty description="Data not Found" />
                           )
                     }
                  </div>
               ) : (
                  <List
                     itemLayout="vertical"
                     size="large"
                     dataSource={dataDetail}
                     renderItem={item => (
                        <List.Item
                           key={item.id}
                           extra={[
                              <div className="right_price">
                                 <p className="from"><small>From</small></p>
                                 <p className="price_tag">{item.price_currency} {item.price}</p>
                                 <p className="tax_tag"><small>All In</small></p>
                                 <div className="select_btn">
                                    <Link to={{
                                       pathname: "/series/" + item.id + "?months=" + encodeURIComponent(qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['months']) + "&depart_date=" + encodeURIComponent(qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['depart_date']) + "&type=" + encodeURIComponent(qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['type'])
                                    }}
                                    >
                                       <Button type="primary" ghost>SELECT</Button>
                                    </Link>
                                 </div>
                                 <div className="clear"></div>
                              </div>
                           ]}
                        >
                           <List.Item.Meta
                              avatar={
                                 <img
                                    alt="logo"
                                    src={item.images.length > 0 ? item.images[0] : homepage_new.default_image}
                                    className="img_sty"
                                 />}
                              title={
                                 <div className="title_panel">
                                    <Paragraph ellipsis={{ rows: 2, }}
                                       className="ellipsis_style ellipsis_style_view">{item.caption}</Paragraph>
                                    <span className="view_sty" onClick={() => this.showModal(item.id, "info_modal")}>
                                       {
                                          homepage_new.suka === 'false' ?
                                          '' : <div class="tour_program">Tour Program</div>
                                       }
                                       <Icon type="question-circle" className="view_icon"/>
                                    </span>
                                    <div className="clear"></div>
                                 </div>
                              }
                              description={[
                                 <div className="content_rate">
                                    <div className="locate">
                                       <span className="locate_warp">
                                          <FontAwesomeIcon size="lg" icon='map-marker-alt' className="location_icon" />{item.country}
                                       </span>
                                       <span className="locate_warp">
                                          <span onClick={() => this.showModal(item.id, "itinerary_modal")} className="itinerary_icon" ><FontAwesomeIcon size="lg" icon='clipboard-list' /> {item.code}</span>
                                          {
                                             item.file_copy_url &&
                                             <CopyToClipboardTooltip text={item.file_copy_url} >
                                                <FontAwesomeIcon size="lg" icon={faCopy} className="itinerary_clipboard_icon" />
                                             </CopyToClipboardTooltip>
                                          }
                                          
                                       </span>
                                       
                                       {item.category !== '' && homepage_new.suka === 'false' ?
                                          <span className="locate_warp">
                                             <img
                                                style={{ width: '6%', height: '100%' }}
                                                alt="logo"
                                                src={item.category == "GD Standard" ? gd : item.category == "FS" ? fs : item.category == "GD Premium" ? premium : item.category == "GD Domestic" ? gd_domestic  : item.category == "GD Signature" ? gd_signature : item.category == "GD FLY CRUISE" ? fly_cruise : item.category.includes("CRUISE") ? cruise : item.category.includes("GD In Depth") ? gd : ""}
                                                className="img_sty"
                                             />
                                          </span>
                                          : ''
                                       }
                                       {item.departure_locations.length > 0 ?
                                          <span className="locate_warp">
                                             {item.departure_locations.map((location) => <Tag color="blue">{location}</Tag>)}
                                          </span>
                                          : ''
                                       }
                                       <div className="date">
                                          <p>Departure Dates</p>
                                          <div className="depart_style">
                                             {departureDates && departureDates[item.id] && this.state.departureDates[item.id].map((departDate) => 
                                                <Link
                                                   to={{pathname: "/series/" + item.id + "?months=" + encodeURIComponent(qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['months']) + "&depart_date=" + encodeURIComponent(departDate.value) + "&type=" + encodeURIComponent(qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['type'])}}
                                                >
                                                   <Tag
                                                      color={departDate.class}
                                                      className="departure-date-link"
                                                   >{departDate.value}</Tag>
                                                </Link>)}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              ]}
                           />
                        </List.Item>
                     )}
                  >
                     { dataLength > this.props.limit && (
                     <Button type="primary" className="load_button" onClick={this.loadMoreData} loading={this.state.loadingMore}  ghost>Show More</Button>
                     )}
                  </List>
                  
               )
            }
            <Modal
               title={
                  <React.Fragment>
                     <span>{modalData.title}</span>
                     <br />
                     <small>{modalData.other_caption}</small>
                  </React.Fragment>
               }
               visible={infoModalVisible}
               onCancel={() => this.onCloseModal('info_modal')}
               footer={null}
               className="modal_series_listing"
            >
               <div>
                  <span className="modal_locate_warp">
                     <FontAwesomeIcon icon='map-marker-alt' size='lg' className="location_icon" />{modalData.location}
                  </span>
                  <span className="modal_locate_warp">
                     <FontAwesomeIcon icon='clipboard-list' size='lg' className="file_icon" />{modalData.type}
                  </span>
                  <span className="modal_locate_warp">
                     {
                        homepage_new.suka === 'false' ?
                        <img
                           style={{ width: '6%', height: '100%' }}
                           alt="logo"
                           src={modalData.category == "GD Standard" ? gd : modalData.category == "FS" ? fs : modalData.category == "GD Premium" ? premium : modalData.category == "GD Domestic" ? gd_domestic : modalData.category == "GD Signature" ? gd_signature : modalData.category == "GD FLY CRUISE" ? fly_cruise : modalData.category.includes("CRUISE") ? cruise : ""}
                           className="img_sty"
                        /> : ''
                     }
                  </span>
               </div>
               <p className="departure_style">Departure Dates</p>
               <p className="departure">{modalData.departdate}</p>
               {
                  modalData.description !== '' ?
                     <React.Fragment>
                        <p className="departure_style">Highlight</p>
                        <p className="departure"><ReactMarkdown source={modalData.description} escapeHtml={false} /></p>
                     </React.Fragment>
                     : ''
               }
            </Modal>

            <Modal
              title={
                  <React.Fragment>
                     <FontAwesomeIcon fixedWidth icon="clipboard-list" size='lg' className="file_icon" />
                     &nbsp; Itinerary&nbsp;&nbsp;&nbsp;&nbsp;
                     <a href={modalData.itinerary_url} target='_blank'>
                        <Button type="primary" icon="download">Download</Button>
                     </a>
                     &nbsp;&nbsp;
                     {
                        modalData.file_copy_url &&
                        <CopyToClipboardTooltip text={modalData.file_copy_url}>
                           <Button>Copy to clipboard</Button>
                        </CopyToClipboardTooltip>
                     }
                  </React.Fragment>
              }
              visible={this.state.itineraryModalVisible}
              width={page_width}
              maskClosable={false}
              onCancel={() => this.onCloseModal('itinerary_modal')}
              footer={[
                <Button type="primary" key="close" onClick={() => this.onCloseModal('itinerary_modal')}>
                  Close
                </Button>,
              ]}
            >
              <Document
                file={modalData.itinerary_url}
                onLoadSuccess={this.onDocumentLoadSuccess}
                error={"There is no itinerary available."}
              >
                { 
                  Array.from(
                    new Array(numPages),
                    (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                    ),
                  ) 
                }
              </Document>
            </Modal>
         </div>
      );
   }
}

export default withRouter(RightPanel);
