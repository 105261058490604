import React, { Component } from 'react';
import Search from '../../search/index';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import { withRouter } from 'react-router-dom';
import API from '../../../../api'
import { Spin } from 'antd';
import Footer from '../../home/footer/index';
import qs from 'qs';

class ListingSeries extends Component {

    constructor(props) {
        super(props);

        const series_search_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        this.state = {
          detail: [],
          total_length: 0,
          isLoading: true,
          limit: 25,
          sortBy: '',
          filterByCategory: [],
          filterByDeptLocation: [],
          keyword: series_search_params['keyword'] != undefined ? series_search_params['keyword'] : '',
          months: series_search_params['months'] != undefined ? series_search_params['months'] : [],
          depart_date: series_search_params['depart_date'] != undefined ? series_search_params['depart_date'] : '',
          page: series_search_params['page'] != undefined ? series_search_params['page'] : 1,
          type: series_search_params['type'] != undefined ? series_search_params['type'] : 'series',
          brands: series_search_params['brands'] != undefined ? series_search_params['brands'] : [],
          params: [],
        }
        this.myRef = React.createRef();
    }

    getUniqueArrayBy(arr, key) {
       return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    componentDidMount() {
        this.apiFetch();
        this.myRef.current.scrollIntoView({
            behavior: "smooth",
        })
        console.log(this.myRef, '---------ref')
    }

    componentDidUpdate() {
        const series_search_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

        if (series_search_params['page'] == undefined && this.state.params != this.props.location.search) {
          this.setState({
              keyword: series_search_params['keyword'] != undefined ? series_search_params['keyword'] : '',
              // month: series_search_params['month'] != undefined ? series_search_params['month'] : '',
              months: series_search_params['months'] != undefined ? series_search_params['months'] : '',
              depart_date: series_search_params['depart_date'] != undefined ? series_search_params['depart_date'] : '',
              page: series_search_params['page'] != undefined ? series_search_params['page'] : 1,
              type: series_search_params['type'] != undefined ? series_search_params['type'] : 'series',
              brands: series_search_params['brands'] != undefined ? series_search_params['brands'] : [],
              params: this.props.location.search,
              limit: 25,
              isLoading: true
          })
          console.log(this.props.location.search)
          // console.log(this.state.params)
          this.apiFetch();
          this.myRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start"
          })
        }
    }

    sortByCallback = (value) => {
      console.log(value, 'SortBy')
      this.setState({
        sortBy: value
      })
    };

    handleCategoryChange = (value) => {
      this.setState({
        filterByCategory: value
      });
    };

    handleDeptLocationChange = (value) => {
      console.log(value, "===value handleDeptLocationChange")
      this.setState({
        filterByDeptLocation: value
      });
    };

    processDetail(data){
      const { sortBy, filterByCategory, filterByDeptLocation } = this.state

      if (data && data.length){
        if (filterByCategory.length > 0) {
          data = data.filter(i => filterByCategory.includes(i.category));
        }
        if (filterByDeptLocation.length > 0) {
          data = data.filter(i => i.departure_locations.find(j => filterByDeptLocation.includes(j)));
        }
        if (sortBy == 'lowest_price') {
          return data.sort((a, b) => a.price - b.price)
        } else if (sortBy == 'highest_price') {
          return data.sort((a, b) => b.price - a.price)
        } else {
          return data
        }
      }
      return data
    }

    apiFetch() {
        // const { params } = this.state;
        const params = this.props.location.search;        
        let url = './api/v1' + this.props.location.pathname;
        var that = this;
        console.log(params);

        API.get(url + params)
            .then(function (response) {
                console.log(response, '------------res apiFetch');
                that.setState({
                    detail: response.data.itineraries,
                    total_length: response.data.itineraries.length > 0 ? response.data.itineraries[0].total_itineraries : 0,
                    isLoading: false,
                    filterByCategory: []
                })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    itineraryFetch = (page) => {
      this.setState({
        isLoading: true,
      });
     
      let outputJson = {
        keyword: this.state.keyword,
        months: this.state.months,
        depart_date: this.state.depart_date,
        page: page,
        type: this.state.type,
        brands: this.state.brands,
      };

      console.log(outputJson, "===outputJson")
      const search = "?" + qs.stringify(outputJson, { arrayFormat: 'brackets' });

      this.props.history.push({
        pathname: '/series',
        search: search
      });   

      let url = './api/v1/series/'+ search;
      var that = this;
      API.get(url)
        .then(function (response) {
          console.log(response.data, '------------res itineraryFetch');
          let all_details = [...that.state.detail, response.data.itineraries];
          that.state.booking_details = that.getUniqueArrayBy(all_details.flat(1), 'id');
         
          that.setState({
            detail: that.state.booking_details,
            total_length: response.data.itineraries.length > 0 ? response.data.itineraries[0].total_itineraries : 0,
            page: page,
            isLoading: false,
            limit: that.state.limit + 25,
            filterByCategory: [],
            params: search
          })
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    };

    render() {

        const { isLoading, detail, sortBy, filterByCategory, filterByDeptLocation, total_length, limit, page } = this.state;
        const data = this.processDetail(detail);

        return (
            <div>
                <Search tagline={homepage_new.tagline} covers={homepage_new.search_covers} />
                <Spin size="large" spinning={isLoading}>
                <div id="ListingSeries" ref={this.myRef} className={homepage_new.suka === 'false' ? ""  : "suka-margin"}>
                    <div className="warp">
                        <LeftPanel detail={detail} sortByCallback={this.sortByCallback} sortBy={sortBy} filterByCategory={filterByCategory} handleCategoryChange={this.handleCategoryChange} filterByDeptLocation={filterByDeptLocation} handleDeptLocationChange={this.handleDeptLocationChange} />
                        <RightPanel detail={data} dataLength={total_length} loading={isLoading} itineraryFetch={this.itineraryFetch} limit={limit} page={page} />
                        <div className="clear"></div>
                    </div>
                </div>
                </Spin>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ListingSeries);
