import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Affix, Badge, Divider } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import API from '../../../../api'
import './index.css'

const { SubMenu } = Menu;

class MenuBooking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      current: 'wifi',
      user: { username: '', credits: 0, credit_limit: 0, access_flight: false, access_wifi: true, access_series: false, access_activity: false, access_hotel: false, access_land_tour: false, access_admin: false },
      top: 0,
      unread_notifications_count: 0
    }
  }

  componentDidMount() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          user: {
            role: response.data.role,
            username: response.data.username,
            credits: response.data.credits,
            credit_limit: response.data.credit_limit,
            access_flight: response.data.access_flight,
            access_wifi: response.data.access_wifi,
            access_series: response.data.access_series,
            access_activity: response.data.access_activity,
            access_hotel: response.data.access_hotel,
            access_land_tour: response.data.access_land_tour,
            access_credit_purchase: response.data.access_credit_purchase,
            access_admin: response.data.access_admin,
            access_subagents: response.data.access_subagents,
          },
          currentTab: that.state.prevTab,
          isLoading: false,
          unread_notifications_count: response.data.unread_notifications_count
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  /****************************** handleClick *************************************/
  handleClick = e => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };


  render() {
    /****************************** menu header dropdown *************************************/
    const menu = (
      <Menu>
        <Menu.Item disabled={true}>Credit: {this.state.user.credits}</Menu.Item>
        <Menu.Divider></Menu.Divider>
        <Menu.Item><b>My Bookings</b></Menu.Item>
        {
          this.state.user.access_admin ?
            <Menu.Item><a href="./admin/dashboard">Admin Dashboard</a></Menu.Item>
          : null
        }
        {
          homepage_new.suka === 'false' ?
            <Menu.Item><Link to="/users/credit_usage">Credit Usage</Link></Menu.Item>
          : null
        }
        <Menu.Item><a href={homepage_new.pdpa} target="_blank">PDPA</a></Menu.Item>
        <Menu.Item><Link to="/users/profile">Edit Profile</Link></Menu.Item>
        <Menu.Item><Link to="/agency/profile">Edit Agency Profile</Link></Menu.Item>
        <Menu.Item><a href="./user/edit">Change Password</a></Menu.Item>
        {
          homepage_new.suka === 'false' ?
            <Menu.Item><a href={homepage_new.memo} target="_blank">Memo</a></Menu.Item>
          : null
        }
        { this.state.user.access_subagents && <Menu.Item><Link to ="/subagents">Subagents</Link></Menu.Item> }
        <Menu.Item><a href="./users/sign_out" >Logout</a></Menu.Item>
      </Menu>
    );
    return (
      <Affix offsetTop={this.state.top}>
      <div className="MenuContainer">
 
        {/******************************************** menu header **************************************************/}
        <div className="menu_header">
          <div className="title">
            <Link to="/" className="a_link">
              <span>{homepage_new.app_name}</span> 
            </Link>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Link to="/notifications">
                  <Badge count={this.state.unread_notifications_count}>
                    <Icon type="bell" theme="twoTone" className="bell-icon" />
                  </Badge>
                </Link>
              </div>
              <Divider type="vertical" />
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="drop_menu"
              >
                <a className="ant-dropdown-link" href="#">
                  {this.state.user.username} <Icon type="down" />
                </a>
              </Dropdown>
            </div>
        </div>

        {/******************************************** menu select **************************************************/}
        <Menu onClick={this.handleClick} selectedKeys={[this.props.selected]}
          mode="horizontal" className="menu_select">
          {
            this.state.user.access_series && <Menu.Item key="series"><Link to="/bookings/series">Series Bookings</Link></Menu.Item>
          }
          {
            homepage_new.suka === 'false' && this.state.user.access_activity && <Menu.Item key="activity"><Link to="/bookings/activities">Activity Bookings</Link></Menu.Item>
          }
          {
            homepage_new.suka === 'false' && this.state.user.access_wifi && <Menu.Item key="wifi"><Link to="/bookings/wifis">Wifi Bookings</Link></Menu.Item>
          }
          {
            homepage_new.suka === 'false' && this.state.user.access_flight && <Menu.Item key="flight"><Link to="/bookings/flights">Flight Bookings</Link></Menu.Item>
          }
          {
            homepage_new.suka === 'false' && this.state.user.access_hotel && <Menu.Item key="hotel"><Link to="/bookings/hotels">Hotel Bookings</Link></Menu.Item>
          }
          {
            homepage_new.suka === 'false' && this.state.user.access_land_tour && <Menu.Item key="land_tour"><Link to="/bookings/land_tours">Ground Tour Bookings</Link></Menu.Item>
          }
          {
            homepage_new.suka === 'false' && <Menu.Item key="voucher_group"><Link to="/bookings/voucher_groups">Voucher Bookings</Link></Menu.Item>
          }
          {/*<Menu.Item key="voucher"><Link to="/bookings/vouchers">Voucher Listing</Link></Menu.Item>*/}  
          {/*this.state.user.access_credit_purchase && <Menu.Item key="purchase_credit"><Link to="/users/purchase_credit">Purchase Credit</Link></Menu.Item>*/}
          
          {
            homepage_new.suka === 'false' && this.state.user.credit_limit > 0 && <Menu.Item key="due_invoice"><Link to="/bookings/due_invoices">Due Invoices</Link></Menu.Item>
          }
          {
            <Menu.Item key="due_payment"><Link to="/bookings/due_payments">Due Payments</Link></Menu.Item>
          }
          {
            <Menu.Item key="listing_statistics"><Link to="/bookings/statistics">Statistics</Link></Menu.Item>
          }
        </Menu>
      </div>
       </Affix>
    );
  }
}

export default MenuBooking;