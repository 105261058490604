import React, { Component } from 'react';
import { Drawer, Button, Spin, Result } from 'antd';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import API from '../../../../api';
import './index.css';

class OrderPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_msg: null,
      order_id: null,
      data: null,
      visible: false,
      loading: true,
      amount: 0,
      payment_type: '',
      collect_type: '',
      top: 0,
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    console.log(this.props.location.pathname)
    let order_id = this.props.location.pathname.match(/payment_orders\/(.+)\/payment/)[1];
    var that = this;
    var url = './api/v1/payment_orders/'+order_id+'/payments/new';
    var params = this.props.location.search;

    API.get(url + params)
    .then(function (response) {
        console.log(response,'------------res');
        if (response.data.error == undefined) {
          that.setState({
            data: response.data.data.attributes,
            error_msg: null,
          })
        }
        else {
          that.setState({
            data: null,
            error_msg: response.data.error,
          })
        }

        that.setState({
          loading: false,
          order_id: order_id,
        })
    })
    .catch(function (error) {
      console.log(error);
      that.setState({
        error_msg: error.response.data?.error || 'Something went wrong.',
        data: null,
        loading: false
      })
    })
    .then(function () {
      // always executed
    });
  }

  handlePaymentType = (value) => {
    console.log(value, "--index payment_type")
    this.setState({
      payment_type: value
    });
  };

  render() {
    const { loading, data, payment_type, order_id, error_msg } = this.state;
    var service_fee = data !== null ? data.cc_rate * data.amount : 0

    return (
      <div id="OrderPayment">
        <div className="MenuContainer">
          {/******************************************** menu header **************************************************/}
          <div className="menu_header">
            <div className="title">
              <span>{homepage_new.app_name}</span>
            </div>
          </div>
        </div>
        <Spin size="large" spinning={loading}>
          {
            data !== null ? (
              <div>
                <div className="warppage">
                  <LeftPanel loading={loading} data={data} handlePaymentType={this.handlePaymentType} service_fee={service_fee} order_id={order_id} />
                  <RightPanel loading={loading} data={data} service_fee={service_fee} payment_type={payment_type} />
                  <div className="clear"></div>
                </div>
                <div className="res_panel">
                  <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
                    Summary
                 </Button>
                  <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.visible}
                  >
                    <RightPanel loading={loading} data={data}/>
                  </Drawer>
                </div>
              </div>
            ) : (null)
          }
          {
            error_msg !== null ? (
              <Result
                status={error_msg.includes("Paid") ? "success" : error_msg.includes("Pending") || error_msg.includes("Refunded") ? "warning" : "error"}
                title={error_msg}
              >
              </Result>
            ) : (null)
          }
        </Spin>
      </div>
    );
  }
}

export default OrderPayment;
