import React from 'react'
import PropTypes from 'prop-types'
import './social_icon.css'

function SocialIcon(props) {
  return (
    <div id="social-icon-container">
      <a href={props.link} target="_blank">
        <img src={props.imageUrl} alt={props.imageAlt} height="50"></img>
      </a>
    </div>
  )
}

SocialIcon.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  link: PropTypes.string.isRequired,
}

export default SocialIcon
