import React from 'react';
import { Menu, Dropdown, Icon } from 'antd';
import { Link } from 'react-router-dom';
import './navigation_bar.css'

const NavigationBar = (props) => {
  const menu = (
    <Menu>
      <Menu.Item disabled={true}>Credit: {props.user.credits}</Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item><Link to="/bookings/series">My Bookings</Link></Menu.Item>
      <Menu.Item>
        <Link to="/bookings/due_payments">Due Payments</Link>
      </Menu.Item>
      {
        homepage_new.suka === 'false' ?
          <Menu.Item><Link to="/users/credit_usage">Credit Usage</Link></Menu.Item>
        : null
      }
      <Menu.Item><a href={homepage_new.pdpa} target="_blank">PDPA</a></Menu.Item>
      <Menu.Item><Link to="/users/profile">Edit Profile</Link></Menu.Item>
      <Menu.Item><Link to="/agency/profile">Edit Agency Profile</Link></Menu.Item>
      {
        homepage_new.suka === 'false' ?
          <Menu.Item><a href={homepage_new.memo} target="_blank">Memo</a></Menu.Item>
        : null
      }
      <Menu.Item><a href="./user/edit">Change Password</a></Menu.Item>
      <Menu.Item><Link to ="/subagents"><b>Subagents</b></Link></Menu.Item>
      <Menu.Item><a href="./users/sign_out" >Logout</a></Menu.Item>
    </Menu>
  );

  return (
    <div className="menu_container">
      <div className="menu_header">
        <div className="title">
          <Link to="/" className="a_link">
            <span>{homepage_new.app_name}</span>
          </Link>
        </div>
        <Dropdown overlay={menu} trigger={['click']} className="drop_menu">
          <a className="ant-dropdown-link" href="#">
            {props.user.username} <Icon type="down" />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default NavigationBar;
