import React, { Component } from 'react';
import { Input, Button, Icon, AutoComplete, Select, DatePicker, Row, Col, Checkbox, Tag } from 'antd';
import moment from 'moment';
import Fuse from 'fuse.js';
import { withRouter, Link } from 'react-router-dom';
import qs from 'qs';
import API from '../../../api';

const { Option, OptGroup } = AutoComplete;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class Series extends Component {

  constructor(props) {
    super(props);
    const series_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.state = {
      keyword: series_params['keyword'] != undefined ? series_params['keyword'] : '',
      months: series_params['months'] != undefined ? series_params['months'] : [],
      depart_date: series_params['depart_date'] != undefined ? series_params['depart_date'] : '',
      ex_end: series_params['ex_end'] != undefined ? series_params['ex_end'] : [],
      brands: series_params['brands'] != undefined ? series_params['brands'] : [],
      guarantees: series_params['guarantees'] != undefined ? series_params['guarantees'] : [],
      destinations: '',
      itineraries: '',
      monthStartDate: '',
    }
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
      this.countryFetch();
      if(homepage_new.suka === 'false'){
        this.itineraryFetch();
      }
      // Change Moment.js "Invalid Date" message
      moment.updateLocale(moment.locale(), { invalidDate: '' });
  }

  countryFetch() {
      let url = './api/v1/series/country_list';
      var that = this;
      // console.log(params);

      API.get(url)
          .then(function (response) {
              // console.log(response, '------------res country');
              that.setState({
                  destinations: response.data
              })
          })
          .catch(function (error) {
              console.log(error);
          })
          .then(function () {
              // always executed
          });
  }

  itineraryFetch() {
      let url = './api/v1/series/itinerary_list';
      var that = this;
      // console.log(params);

      API.get(url)
          .then(function (response) {
              // console.log(response, '------------res country');
              that.setState({
                  itineraries: response.data
              })
          })
          .catch(function (error) {
              console.log(error);
          })
          .then(function () {
              // always executed
          });
  }

  /****************************** submitForm *******************************/
  submitForm(event) {
      // event.preventDefault();

      let outputJson = {
        keyword: this.state.keyword,
        months: this.state.months,
        depart_date: this.state.depart_date,
        ex_end: this.state.ex_end,
        brands: this.state.brands,
        guarantees: this.state.guarantees,
        type: "series"
      };

      console.log("This is the outputJson", outputJson);
      console.log(qs.stringify(outputJson, { arrayFormat: 'brackets' }))
      console.log(this.props)

      this.props.history.push({
          pathname: '/series',
          search: "?" + qs.stringify(outputJson, { arrayFormat: 'brackets' })
      });      
  }

  /****************************** monthOnChange *******************************/
  monthOnChange(dateInput) {
    this.setState({
      monthStartDate: dateInput[0],
      months: dateInput
    })
  }

  /****************************** dateOnChange *******************************/
  dateOnChange(dateString) {
    this.setState({ 
      depart_date: moment(dateString).format(dateFormat)
    });
  }

  handleFormChange(type, value){
    this.setState({
      [type]: value
    })
  }

  isDateDisabled(current) {
    if (this.state.monthStartDate) {
      return (
        current <= moment(this.state.monthStartDate) || current >= moment(this.state.monthStartDate).add(2, 'month')
      )
    }
    return moment().startOf('date') > current || moment().add(24, 'month') <= current
  }

  setMonthStartDate(dates) {
    this.setState({
      monthStartDate: dates[0]
    })
  }

  handleButtonPress = () => {
    this.submitForm();
  };

  handleKeyPress = event => {
    if (event.key == 'Enter') {
      this.submitForm();
    }
  };

  render() {

    if (this.state.destinations.countries == undefined || this.state.destinations.countries === '') {
      var countries = [];
    }
    else {
      var countries = this.state.destinations.countries
      var dataCountries = [];
      countries.map((country,index) => 
        dataCountries.push({"name": country})
      )
    }

    if (this.state.itineraries.codes == undefined || this.state.itineraries.codes === '') {
      var codes = [];
    }
    else {
      var codes = this.state.itineraries.codes
      var dataCodes = [];
      codes.map((code) => 
        dataCodes.push({"name": code[1]})
      )
    }

    var dataSource = [
      { "title": "Destinations", "children": dataCountries },
      { "title": "Itineraries", "children": dataCodes },
    ]

    const options = dataSource
      .map(item => (
        <OptGroup key={item.title} label={<strong>{item.title}</strong>}>
            {item.children && item.children.map(opt => (
              <Option key={opt.name} value={opt.name}>
                {opt.name}
              </Option>
            ))}
        </OptGroup>
      ))

    const ex_end = [];
    homepage_new.ex_options.map(item => (
      ex_end.push(<Option key={item}>{item}</Option>)
    ))

    const brand_options = [];
    homepage_new.brand_options.map(item => (
      brand_options.push(<Option key={item.id} value={item.id}>{item.value}</Option>)
    ))

    return (
      <div className="Series" >
        <form onSubmit={this.submitForm} className="form_warp">
          <Row type="flex" justify="start" gutter={[16,8]}>
            <Col xs={24} sm={24} md={8}>
              <label>{homepage_new.suka === 'false' ? "Keyword" : "Destinasi"}</label>
              <div className="certain-category-search-wrapper">
                <AutoComplete
                  className="certain-category-search"
                  dropdownClassName="certain-category-search-dropdown"
                  allowClear={true}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 300 }}
                  style={{ width: '100%' }}
                  dataSource={options}
                  placeholder={homepage_new.suka === 'false' ? "Search caption, destination, tour..."  : "Carian destinasi"}
                  optionLabelProp="value"
                  filterOption={true}
                  optionFilterProp={"children"}
                  onChange={(value) => this.handleFormChange('keyword', value)}
                  defaultValue={this.state.keyword}
                >
                  <Input onKeyPress={this.handleKeyPress} suffix={<Icon type="search" className="certain-category-icon" />} />
                </AutoComplete>
              </div>
            </Col>
            {
              <Col xs={24} sm={24} md={8}>
                <Row type="flex" gutter={[8, 8]} justify="start">
                  <Col span={24}>
                    <label>{homepage_new.suka === 'false' ? "Travelling Period" : "Tarikh"}</label>
                    <RangePicker
                      placeholder="YY/MM"
                      onChange={(date, dateString) => this.monthOnChange(dateString)}
                      defaultValue={this.state.months.length > 0 ? this.state.months.map(date => date && moment(date, "YYYY-MM-DD")) : [] }
                      disabledDate={current => this.isDateDisabled(current)}
                      onCalendarChange={(dates) => this.setMonthStartDate(dates)}
                    />
                  </Col>
                  <Col span={24}>
                    <Checkbox.Group
                      onChange={(values) => this.handleFormChange('guarantees', values)}
                      defaultValue={this.state.guarantees}
                      style={{width: "100%"}}
                    >
                      <Row type="flex" justify="start" gutter={[8, 8]}>
                        <Col>
                          <Checkbox value="guarantee">
                            <Tag color="green">
                              Guarantee
                            </Tag>
                          </Checkbox>
                        </Col>
                        <Col>
                          <Checkbox value="almost_guarantee">
                            <Tag color="cyan">
                              Almost Guarantee
                            </Tag>
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Col>
                </Row>
              </Col> 
            }
            {
              homepage_new.suka === 'false' ? (
                <Col xs={24} sm={12} md={4}>
                  <label>EX-END</label>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select EX-END"
                    defaultValue={this.state.ex_end}
                    onChange={(value) => this.handleFormChange('ex_end', value)}
                  >
                    {ex_end}
                  </Select>
                </Col>
              ) : (null)
            }
            {
              homepage_new.suka === 'false' ? (
                <Col xs={24} sm={12} md={4}>
                  <label>Brand</label>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select brand"
                    defaultValue={this.state.brands}
                    onChange={(value) => this.handleFormChange('brands', value)}
                  >
                    {brand_options}
                  </Select>
                </Col>
              ) : (null)
            }
            <Col span={24}>
              <div className="btn_submit">
                <Button type="primary" onClick={this.handleButtonPress} icon="search" size="default" className="search_btn">
                    {homepage_new.suka === 'false' ? "Search"  : "Carian"}
                </Button>
              </div>
            </Col>
          </Row>
        </form>

      </div>
    );
  }
}

export default withRouter(Series);
