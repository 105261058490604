import React, { Component } from 'react';
import { List, Select, Icon, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css'
import AgentContact from '../../agentContact'

class AgentsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      agent_states: [],
      selected_state: '',
      search_names: '',
      infoModalVisible: false,
      modalData: { full_name: '', whatsapp_no: '', phone: '', address: '', city: '', state: '', email: '', image: '' },
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps != this.props && this.props.agents != []){
      this.setState({
        agent_states: [...new Set(this.props.agents.map((agent) => { return agent.state}))]
      })
      console.log("Agent states",[...new Set(this.props.agents.map((agent) => { return agent.state}))])
    }
  }

  handleChange = (value) => {
    console.log("select handle change value", value);
    this.setState({
      selected_state: value === undefined ? '' : value
    });
  }

  handleChangeName = (event) => {
    console.log("select handle change value name", event.target.value);
    this.setState({
      search_names: event.target.value === undefined ? '' : event.target.value.toUpperCase()
    });
  }

  showModal = (id, type) => {
    const modalData = this.props.agents.find(elm => {
      return elm.id === id
    })

    this.setState({
      modalData: {
        full_name: modalData.full_name, whatsapp_no: modalData.whatsapp_no, phone: modalData.phone, address: modalData.address, city: modalData.city, state: modalData.state, email: modalData.email, image: modalData.image
      }
    });

    if (type == "info_modal") {
      this.setState({
        infoModalVisible: true,
      });
    }
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      infoModalVisible: false,
    });
  };

  render_header() {
    return (
      <div>
        <h2>{is_logged_in ? '' : [ homepage_new.suka === 'false' ? 'Contact Agent' : <b>HUBUNGI AGENT KAMI</b>]}</h2>
        <Input onSearch allowClear onChange={this.handleChangeName} type='text' value={this.state.search_names} placeholder={homepage_new.suka === 'false' ? "Search Agents" : "Cari Ejen"} style={{width: '100%'}}/>
        {
          this.state.agent_states != [] &&
          (
            <Select onSearch allowClear onChange={this.handleChange} placeholder={homepage_new.suka === 'false' ? "Select States" : "Pilih Negeri"} style={{width: '100%'}}>
              {
                this.state.agent_states.map((state, i) => {
                  return (
                    <Option key={i} value={state}>{state}</Option>
                  ) })
              }
            </Select>
          )
        }
        
      </div>
    )
  }

  action_list(item){
    var list = [];
    if(item.whatsapp_no != "" || item.phone != "" || item.email != ""){
      list.push(<span className="view_sty" onClick={() => this.showModal(item.id, "info_modal")}><Icon type="info-circle" className="view_icon"/></span>)
    }
    return list
  }

  render() {

    return (
      <div id="AgentsList" className={this.props.className}>
        <List
          header={this.render_header()}
          bordered
          dataSource={this.props.agents.filter((agent) => { 
            return this.state.selected_state === '' || this.state.selected_state === agent.state
          }).filter((agent) => {return this.state.search_names === '' || agent.full_name.includes(this.state.search_names.toUpperCase())})}
          renderItem={item => (
            <List.Item
              actions={this.action_list(item)}
            >
              <List.Item.Meta
                title={item.full_name}
              />
            </List.Item>
          )}
        />
        <AgentContact handleCancel={this.handleCancel.bind(this)} modalData={this.state.modalData} infoModalVisible={this.state.infoModalVisible} />
      </div>
    )
  }

}

export default AgentsList;