import React, { Component } from 'react';
import { Select, Checkbox, Radio, InputNumber } from 'antd';
import master from '../../../../images/payment/master.png';
import instant from '../../../../images/payment/instant.png'
import fpxnew from '../../../../images/payment/fpx-new.png';
import payLater from '../../../../images/payment/paylater.png';
import dobw from '../../../../images/payment/duitnow.png';
import PropTypes from 'prop-types';

const { Option } = Select;

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentTypeAvailable: this.props.paymentTypeAvailable
    }
  }

  priceFormat(value) {
    return value === undefined ? 0.00 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const { paymentTypeAvailable } = this.state;
    var grand_total = (this.props.final_price > 0 || this.props.service_fee > 0) ? (this.props.final_price + this.props.service_fee) : 0;
    // Suka able to pay deposit as low as 30%
    var deposit_amount = this.props.deposit !== undefined ? homepage_new.suka === 'true' ? ((30/100) * this.props.deposit.toFixed(2)) : this.props.deposit.toFixed(2) : 0;
    
    return (
      <div className="payment">
        <div className="payment_type">
          <input type="radio" id="control_06" name="select" value="dobw" checked={this.props.payment_type === "dobw"} onChange={this.props.handlePaymentChange} disabled={this.props.dobw_disabled} />
          <label htmlFor="control_06">
            <img src={dobw} />
            <p className="credit_style">Online Banking/Wallets</p>
          </label>
        </div>

        <div className="payment_type">
          <input type="radio" id="control_01" name="select" value="public_bank_2" checked={this.props.payment_type === "public_bank_2"} disabled={this.props.master_disabled} onChange={this.props.handlePaymentChange} disabled={this.props.pbb2_disabled} />
          <label htmlFor="control_01">
            <img src={master} />
          </label>
        </div>

        <div className="payment_type">
          <input type="radio" id="control_03" name="select" value="fpx_b2c" checked={this.props.payment_type === "fpx_b2c"} onChange={this.props.handlePaymentChange} disabled={this.props.fpx_disabled || (this.props.allow_fpxb2c_overlimit === true ? '' : this.props.final_price > 30000) || this.props.fpxb2c_disabled} />
          <label htmlFor="control_03">
            <img src={fpxnew} />
            <p className="credit_style">B2C (Retail Banking)</p>
          </label>
        </div>
        
        <div className="payment_type">
          <input type="radio" id="control_04" name="select" value="fpx_b2b" checked={this.props.payment_type === "fpx_b2b"} onChange={this.props.handlePaymentChange} disabled={this.props.fpx_disabled || this.props.fpxb2b_disabled} />
          <label htmlFor="control_04">
            <img src={fpxnew} />
            <p className="credit_style">B2B (Corporate Banking)</p>
          </label>
        </div>

        {
          this.props.no_credit_payment == true || (this.props.pageName == "VoucherShowPage" && this.props.userStatus == false) ? 
          '' : (
            <div className="payment_type">
              <input type="radio" id="control_02" name="select" value="credit_transaction" checked={this.props.payment_type === "credit_transaction"} disabled={this.props.credit_disabled || this.props.instant_access}
                onChange={this.props.handlePaymentChange} />
              <label htmlFor="control_02">
                <img src={instant} />
                <p className="credit_style">Credit Available: {this.props.credits}</p>
              </label>
            </div>
          )
        }

        {
          paymentTypeAvailable === 4 ?
            <div className="payment_type">
              <input type="radio" id="control_05" name="select" value="pay_later" checked={this.props.payment_type === "pay_later"} onChange={this.props.handlePaymentChange} disabled={this.props.paylater_disabled} />
              <label htmlFor="control_05">
                <img src={payLater} />
              </label>
            </div> : null
        }

        <div className="clear"></div>

        <div className="after_select" >
          {
            this.props.method_visible == true ?
              (
                <div className="form_control">
                  <label>{this.props.payment_type.includes("fpx") || this.props.payment_type.includes("dobw") ? "Bank" : "Card Method"}</label>

                  <Select showSearch placeholder="Select your card method"
                    onChange={this.props.handlePaymentMethodChange}
                    defaultOpen={true}
                    className="payment_select"
                    value={this.props.payment_type.includes("dobw") ? this.props.payment_method + " - " + this.props.bank_type : this.props.payment_method}
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {
                      (
                        this.props.banklist_select.map((bank) => {
                          return (
                            <Option key={bank[1]} value={bank[1]} disabled={bank[0].includes("Offline") || (this.props.pageName == "ThirdPartyOrderPaymentPage" && ((bank[0].includes("Corporate") && this.props.dobw_cor_disabled) || (bank[0].includes("Retail") && this.props.dobw_ret_disabled)))}>{bank[0]}</Option>
                          )
                        })
                      )
                    }
                  </Select>
                  <div className="error_payment_method">
                    <p className="error">{this.props.validationErrors.payment_method}</p>
                  </div>

                  <div className="clear"></div>

                </div>
              )
              : (null)
          }
          {
            homepage_new.suka === 'false' && this.props.pageName != "DuePaymentShowPage" && this.props.pageName != "ThirdPartyOrderPaymentPage" && this.props.pageName != "StandalonePaymentPage" && this.props.pageName != "OrderPaymentPage" && !this.props.allow_customer_pay && (this.props.payment_type.includes("fpx") || this.props.payment_type.includes("dobw") || this.props.payment_type.includes("public_bank")) ?
                (
                  <div className="form_control">
                    <label>Payment Terms</label>
                    <Radio.Group defaultValue={this.props.fare_type_selected} onChange={(e) => this.props.onChangeData(0, 'fare_type_selected', e.target.value)}>
                      { this.props.is_agent && <Radio value="agent_deposit" disabled={this.props.fare_type !== "deposit" || (this.props.pageName === "DedicatedPaymentPage" && this.props.disable_deposit)}>Agent Deposit</Radio> }
                      { this.props.is_agent && <Radio value="agent_full_fare">Agent Full Payment</Radio> }
                      { !this.props.is_agent && <Radio value="agent_deposit" disabled={this.props.fare_type !== "deposit" || (this.props.pageName === "DedicatedPaymentPage" && this.props.disable_deposit)}>Deposit</Radio> }
                      { !this.props.is_agent && <Radio value="agent_full_fare">Full Payment</Radio> }  
                      {
                        (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") ? (
                          <Radio value="others" disabled={this.props.disable_others}>Others</Radio>
                        ) : (null)
                      }
                    </Radio.Group>
                    <div className="error_payment_method">
                      <p className="error">{this.props.validationErrors.fare_type_selected}</p>
                    </div>
                    <br/>
                    {
                      (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") && this.props.fare_type_selected === "others" ? (
                        <div>
                          <label>Set Payment Amount</label>
                          {
                            this.props.pageName === "SeriesShowPage" ? (
                              <InputNumber className="set_amount SeriesShowPage" min={this.props.fare_type === "deposit" ? this.props.deposit.toFixed(2) : this.props.final_price} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.fare_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(this.props.final_price)} />
                            ) : (
                              <InputNumber className="set_amount" min={this.props.collect_type === "deposit" ? this.props.deposit.toFixed(2) : 1} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.collect_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(1)} />
                            )
                          }
                          <div className="error_payment_method">
                            <p className="error">{this.props.validationErrors.set_amount}</p>
                          </div>
                        </div>
                      ) : (null)
                    }
                  </div>
                )
            : (null)
          }
          {
            homepage_new.suka === 'false' && this.props.pageName != "DuePaymentShowPage" && this.props.pageName != "ThirdPartyOrderPaymentPage" && this.props.pageName != "StandalonePaymentPage" && this.props.pageName != "OrderPaymentPage" && this.props.fare_type === "deposit" && this.props.allow_customer_pay && (this.props.payment_type.includes("fpx") || this.props.payment_type.includes("dobw") || this.props.payment_type.includes("public_bank")) ?
                (
                  <div className="form_control">
                    <label>Payment Terms</label>
                    <Radio.Group onChange={(e) => this.props.onChangeData(0, 'fare_type_selected', e.target.value)}>
                      { this.props.is_agent && <Radio value="agent_deposit" disabled={(this.props.pageName === "DedicatedPaymentPage" && this.props.disable_deposit)}>Agent Deposit</Radio> }
                      { this.props.is_agent && <Radio value="agent_full_fare">Agent Full Payment</Radio> }
                      { this.props.is_agent && <Radio value="customer_deposit" disabled={(this.props.pageName === "DedicatedPaymentPage" && this.props.disable_deposit)}>Customer Deposit</Radio> }
                      { this.props.is_agent && <Radio value="customer_full_fare">Customer Full Payment</Radio> }
                      { !this.props.is_agent && <Radio value="agent_deposit" disabled={(this.props.pageName === "DedicatedPaymentPage" && this.props.disable_deposit)}>Deposit</Radio> }
                      { !this.props.is_agent && <Radio value="agent_full_fare">Full Payment</Radio> }
                      {
                        (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") && this.props.payment_type !== "" ? (
                          <Radio value="others" disabled={this.props.disable_others}>Others</Radio>
                        ) : (null)
                      }
                    </Radio.Group>
                    <div className="error_payment_method">
                      <p className="error">{this.props.validationErrors.fare_type_selected}</p>
                    </div>
                    <br/>
                    {
                      (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") && this.props.fare_type_selected === "others" ? (
                        <div>
                          <label>Set Payment Amount</label>
                          {
                            this.props.pageName === "SeriesShowPage" ? (
                              <InputNumber className="set_amount SeriesShowPage" min={this.props.fare_type === "deposit" ? this.props.deposit.toFixed(2) : this.props.final_price} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.fare_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(this.props.final_price)} />
                            ) : (
                              <InputNumber className="set_amount" min={this.props.collect_type === "deposit" ? this.props.deposit.toFixed(2) : 1} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.collect_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(1)} />
                            )
                          }
                          <div className="error_payment_method">
                            <p className="error">{this.props.validationErrors.set_amount}</p>
                          </div>
                        </div>
                      ) : (null)
                    }
                  </div>
                )
            : (null)
          }
          {
            homepage_new.suka === 'false' && this.props.pageName != "DuePaymentShowPage" && this.props.pageName != "ThirdPartyOrderPaymentPage" && this.props.pageName != "StandalonePaymentPage" && this.props.pageName != "OrderPaymentPage" && (this.props.fare_type === "full fare" || this.props.fare_type === "balance") && this.props.allow_customer_pay && (this.props.payment_type.includes("fpx") || this.props.payment_type.includes("dobw") || this.props.payment_type.includes("public_bank")) ?
            (
              <div className="form_control">
                <label>Payment Terms</label>
                <Radio.Group onChange={(e) => this.props.onChangeData(0, 'fare_type_selected', e.target.value)}>
                  { this.props.is_agent && <Radio value="agent_full_fare">Agent Full Payment</Radio> }
                  { this.props.is_agent && <Radio value="customer_full_fare">Customer Full Payment</Radio> }
                  { !this.props.is_agent && <Radio value="agent_full_fare">Full Payment</Radio> }
                  {
                    (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") && this.props.payment_type !== "" ? (
                      <Radio value="others" disabled={this.props.disable_others}>Others</Radio>
                    ) : (null)
                  }
                </Radio.Group>
                <div className="error_payment_method">
                  <p className="error">{this.props.validationErrors.fare_type_selected}</p>
                </div>
                <br/>
                {
                  (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") && this.props.fare_type_selected === "others" ? (
                    <div>
                      <label>Set Payment Amount</label>
                      {
                        this.props.pageName === "SeriesShowPage" ? (
                          <InputNumber className="set_amount SeriesShowPage" min={this.props.fare_type === "deposit" ? this.props.deposit.toFixed(2) : this.props.final_price} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.fare_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(this.props.final_price)} />
                        ) : (
                          <InputNumber className="set_amount" min={this.props.collect_type === "deposit" ? this.props.deposit.toFixed(2) : 1} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.collect_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(1)} />
                        )
                      }
                      <div className="error_payment_method">
                        <p className="error">{this.props.validationErrors.set_amount}</p>
                      </div>
                    </div>
                  ) : (null)
                }
              </div>
            ) : (null)
          }

          {
            homepage_new.suka === 'false' && this.props.pageName != "DuePaymentShowPage" && this.props.pageName != "ThirdPartyOrderPaymentPage" && this.props.pageName != "StandalonePaymentPage" && this.props.pageName != "OrderPaymentPage" && this.props.payment_type.includes("credit_transaction") && (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") ?
            (
              <div className="form_control">
                <label>Payment Terms</label>
                <Radio.Group onChange={(e) => this.props.onChangeData(0, 'fare_type_selected', e.target.value)}>
                  <Radio value="others" disabled={this.props.disable_others}>Others</Radio>
                </Radio.Group>
                <div className="error_payment_method">
                  <p className="error">{this.props.validationErrors.fare_type_selected}</p>
                </div>
                <br/>
                {
                  this.props.fare_type_selected === "others" ? (
                    <div>
                      <label>Set Payment Amount</label>
                      {
                        this.props.pageName === "SeriesShowPage" ? (
                          <InputNumber className="set_amount SeriesShowPage" min={this.props.fare_type === "deposit" ? this.props.deposit.toFixed(2) : this.props.final_price} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.fare_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(this.props.final_price)} />
                        ) : (
                          <InputNumber className="set_amount" min={this.props.collect_type === "deposit" ? this.props.deposit.toFixed(2) : 1} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.collect_type === "deposit" ? "Min payment amount RM" + this.priceFormat(this.props.deposit) : "Min payment amount RM" + this.priceFormat(1)} />
                        )
                      }
                      <div className="error_payment_method">
                        <p className="error">{this.props.validationErrors.set_amount}</p>
                      </div>
                    </div>
                  ) : (null)
                }
              </div>
            ) : (null)
          }

          {
            homepage_new.suka === 'true' && this.props.pageName != "DuePaymentShowPage" && this.props.pageName != "ThirdPartyOrderPaymentPage" && this.props.pageName != "StandalonePaymentPage" && this.props.pageName != "OrderPaymentPage" && (this.props.pageName === "DedicatedPaymentPage" || this.props.pageName === "SeriesShowPage") && (this.props.payment_type != "" && !this.props.payment_type.includes("pay_later")) ?
            (
              <div className="form_control">
                <label>Payment Terms</label>
                <Radio.Group onChange={(e) => this.props.onChangeData(0, 'fare_type_selected', e.target.value)} value={this.props.fare_type_selected} >
                  <Radio value="others" disabled={this.props.disable_others} >Others</Radio>
                </Radio.Group>
                <div className="error_payment_method">
                  <p className="error">{this.props.validationErrors.fare_type_selected}</p>
                </div>
                <br/>
                {
                  this.props.fare_type_selected === "others" ? (
                    <div>
                      <label>Set Payment Amount</label>
                      {
                        this.props.pageName === "SeriesShowPage" ? (
                          <InputNumber className="set_amount SeriesShowPage" min={this.props.fare_type === "deposit" ? deposit_amount : this.props.final_price} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.fare_type === "deposit" ? "Min payment amount RM" + this.priceFormat(deposit_amount) : "Min payment amount RM" + this.priceFormat(this.props.final_price)} />
                        ) : (
                          <InputNumber className="set_amount" min={this.props.collect_type === "deposit" ? deposit_amount : 1} precision={2} step={0.1} onChange={(e) => this.props.onChangeData(0, 'set_amount', e)} placeholder={this.props.collect_type === "deposit" ? "Min payment amount RM" + this.priceFormat(deposit_amount) : "Min payment amount RM" + this.priceFormat(1)} />
                        )
                      }
                      <div className="error_payment_method">
                        <p className="error">{this.props.validationErrors.set_amount}</p>
                      </div>
                    </div>
                  ) : (null)
                }
              </div>
            ) : (null)
          }

          <Checkbox onChange={this.props.onChangePaymentCheckBox} >
            <span className="terms_and_conditions">
              By proceeding, I acknowledge that I have read and agreed to
              this {this.props.terms ? (<a href={this.props.terms} target="_blank">Terms of Use and Privacy Statement</a>) : ('Terms of Use and Privacy Statement.')}
            </span>
            <div className="error_payment_method">
              <p className="error">{this.props.validationErrors.agree}</p>
            </div>
          </Checkbox>

          <div className="clear"></div>
        </div>

      </div>

    )
  }
}

PaymentMethod.propTypes = {
  is_agent: PropTypes.string,
}

PaymentMethod.defaultProps = {
  is_agent: true,
}

export default PaymentMethod;
