import React, { Component } from "react";
import useState from "react";
import {
  Card,
  Button,
  Form,
  Input,
  Menu,
  Dropdown,
  Icon,
  Tag,
  message,
  Row,
  Col,
  List,
  Badge,
  Typography,
  Modal,
  Skeleton,
  Spin,
  Empty,
  Divider
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import API from "../../../api";
import "./index.css";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { Tabs } from "antd";

const { Title, Paragraph, Text } = Typography;
const { SubMenu } = Menu;

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      notificationModalLoading: false,
      user: [],
      notifications: [],
      notifications_list: [],
      total_length: 0,
      page: 1,
      limit: 10,
      page_width: 800,
      notificationModalVisible: false,
      unread_notifications_count: 0,
      selectedData: "",
      loadingMore: false,
      activeKey: "unread",
      is_unread: true,
    };
  }

  componentDidMount() {
    const that = this;

    this.userDetailFetch();
    this.notificationFetch(this.state.page);
  }

  userDetailFetch = (id) => {
    var that = this;
    API.get("./api/v1/users/details")
      .then(function (response) {
        console.log(response, "------------user details res");
        that.setState({
          user: response.data,
          unread_notifications_count: response.data.unread_notifications_count
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  notificationDetailFetch = (id) => {
    let url = "./api/v1/notifications/" + id;
    var that = this;
    API.get(url)
      .then(function (response) {
        console.log(response.data, "------------res notificationDetailFetch");
        that.setState({
          notificationModalVisible: true,
          selectedData: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  notificationFetch = (page) => {
    this.setState({
      isLoading: true,
    });

    let params = "?page=" +
    this.state.page +
    "&limit=" +
    this.state.limit;

    if(this.state.activeKey == 'unread'){
      params += "&is_unread=" + this.state.is_unread
    }
    else{
      params += "&notify_type=" +
      this.state.activeKey
    }

    let url =
      "./api/v1/notifications" +
      params
    var that = this;
    API.get(url)
      .then(function (response) {
        console.log(response.data, "------------res notificationFetch");
        if (page == 1 || (response.data.data_items.length == 0 && page > 1)) {
          that.state.notifications_list = [];
        } 
        let all_notifications = [
          ...that.state.notifications_list,
          response.data.data_items,
        ];
        that.state.notifications_list = that.getUniqueArrayBy(
          all_notifications.flat(1),
          "id"
        );

        let state_json = {};
        state_json = {
          notifications: that.state.notifications_list,
          loading: false,
          total_length:
            response.data.data_items.length > 0
              ? response.data.total_notifications
              : 0
        };
        that.setState(state_json);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  showModal = (id) => {
    this.notificationDetailFetch(id);
  };

  hideModal = () => {
    this.setState({
      notificationModalVisible: false,
    });
    this.notificationFetch(this.state.page);
    this.userDetailFetch();
  };

  loadMoreData = () => {
    this.setState({
      loadingMore: true,
    });

    if (this.state.limit >= this.state.total_length) {
      this.setState({
        loadingMore: false,
        hasMore: false,
      });
    } else {
      this.setState(
        {
          page: this.state.page + 1,
          loadingMore: false,
        },
        () => {
          this.notificationFetch(this.state.page);
        }
      );
    }
  };

  getUniqueArrayBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  onChange = (key) => {
    let is_unread = false;

    if (key == "unread") {
      is_unread = true;
    }

    // Use setState
    this.setState(
      {
        is_unread: is_unread,
        activeKey: key,
        page: 1,
      },
      () => {
        // Fetch notifications after state has been updated
        this.notificationFetch(this.state.page);
      }
    );
  };

  render() {
    const items = [
      { key: "unread", label: `Unread (${this.state.unread_notifications_count})` },
      { key: "announcements", label: `Announcements` },
      { key: "reminders", label: `Reminders` },
    ];

    const menu = (
      <Menu>
        <Menu.Item disabled={true}>Credit: {this.state.user.credits}</Menu.Item>
        <Menu.Divider></Menu.Divider>
        <Menu.Item>
          <Link to="/bookings/series">My Bookings</Link>
        </Menu.Item>
        {homepage_new.suka === "false" ? (
          <Menu.Item>
            <Link to="/users/credit_usage">Credit Usage</Link>
          </Menu.Item>
        ) : null}
        <Menu.Item>
          <a href={homepage_new.pdpa} target="_blank">
            PDPA
          </a>
        </Menu.Item>
        <Menu.Item>
          <Link to="/users/profile">
            <b>Edit Profile</b>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/agency/profile">
            <b>Edit Agency Profile</b>
          </Link>
        </Menu.Item>
        {homepage_new.suka === "false" ? (
          <Menu.Item>
            <a href={homepage_new.memo} target="_blank">
              Memo
            </a>
          </Menu.Item>
        ) : null}
        <Menu.Item>
          <a href="./user/edit">Change Password</a>
        </Menu.Item>
        <Menu.Item>
          <a href="./users/sign_out">Logout</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div id="profile">
        <div className="user">
          <div className="logo_top">
            <Link to="/">
              <div className="logo_warp">
                <img src={homepage_new.app_logo} alt="ice" />
                <span>
                  {homepage_new.suka === "false" ? homepage_new.app_name : ""}
                </span>
              </div>
            </Link>
          </div>
          <div className="btn_user">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Link to="/notifications">
                  <Badge count={this.state.unread_notifications_count}>
                    <Icon type="bell" theme="twoTone" className="bell-icon" />
                  </Badge>
                </Link>
              </div>
              <Divider type="vertical" />
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="drop_menu"
              >
                <a className="ant-dropdown-link" href="#">
                  {this.state.user.username} <Icon type="down" />
                </a>
              </Dropdown>
            </div>
          </div>
          <div className="clear"></div>
        </div>

        <div className="content_user">
          <div className="content_warp">
            <h2>Notifications</h2>
            <Tabs
              defaultActiveKey="unread"
              activeKey={this.state.activeKey}
              onChange={this.onChange}
            >
              {items.map((item) => (
                <Tabs.TabPane tab={item.label} key={item.key}>
                  <Card>
                    {this.state.notifications.length == 0 ? (
                      <div className="empty">
                        {this.state.loading ? (
                          <Skeleton active />
                        ) : (
                          <Empty description="Notification not Found" />
                        )}
                      </div>
                    ) : (
                      <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={this.state.notifications}
                        renderItem={(item) => (
                          <List.Item
                            key={item.id} // Assuming each item has a unique 'id'
                            className={"highlight-row-notification"}
                            onClick={() => this.showModal(item.id)}
                          >
                            <Row>
                              <Col span={16} push={4}>
                                <Text strong>{item.title}</Text>
                                <br />
                                <br />
                                {item.created_at}
                              </Col>
                              <Col
                                span={4}
                                pull={16}
                                className="icon-container"
                              >
                                <div className="icon-circle">
                                  <FontAwesomeIcon
                                    icon={faBullhorn}
                                    color="red"
                                  />
                                </div>
                              </Col>
                              <Col span={2} push={2}>
                                {item.is_read ? (
                                  ""
                                ) : (
                                  <Badge color="#f50" size={3} count={5} />
                                )}
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      >
                        {this.state.total_length > this.state.limit && (
                          <Button
                            type="primary"
                            className="load_button"
                            onClick={this.loadMoreData}
                            loading={this.state.loadingMore}
                            ghost
                          >
                            Show More
                          </Button>
                        )}
                      </List>
                    )}
                  </Card>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
        <div className="clear"></div>

        <Modal
          title={
            <React.Fragment>
              {this.state.selectedData.file_url != "" ? (
                <a href={this.state.selectedData.file_url} target="_blank">
                  <Button type="primary" icon="download">
                    Download EN
                  </Button>
                </a>
              ) : (
                ""
              )}
              &nbsp;
              {this.state.selectedData.cn_file_url != "" ? (
                <a href={this.state.selectedData.cn_file_url} target="_blank">
                  <Button type="primary" icon="download">
                    Download CN
                  </Button>
                </a>
              ) : (
                ""
              )}
              <br />
            </React.Fragment>
          }
          visible={this.state.notificationModalVisible}
          width={this.state.page_width}
          confirmLoading={this.state.notificationModalLoading}
          maskClosable={false}
          onCancel={(e) => this.hideModal()}
          footer={[
            <Button
              type="primary"
              key="close"
              onClick={(e) => this.hideModal()}
            >
              Close
            </Button>,
          ]}
        >
          <Typography>
            <Title level={4}>{this.state.selectedData.title}</Title>
            <Text className="italic-text">
              Dated: {this.state.selectedData.created_at}
            </Text>
          </Typography>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.selectedData.description
                ? this.state.selectedData.description.body
                : "",
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default withRouter(Notification);
