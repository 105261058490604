import React, { Component } from "react";
import {
  Card,
  Button,
  Form,
  Input,
  Menu,
  Dropdown,
  Icon,
  Tag,
  message,
  Badge,
  Divider,
  AutoComplete,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import API from "../../../../api";
import "./index.css";
import { debounce } from "../../subagents/debounce";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      user: [],
      unread_notifications_count: 0,
      country: '',
      cities: [],
    };
  }

  componentDidMount() {
    const that = this;

    API.get("./api/v1/users/details")
      .then(function (response) {
        console.log(response, "------------user details res");
        that.setState({
          user: response.data,
          unread_notifications_count: response.data.unread_notifications_count,
          country: response.data.country,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  isEditMode = () => {
    this.setState((prevState) => ({
      isEditMode: !prevState.isEditMode,
    }));
  };

  addFields(type) {
    if (type == "fax") {
      this.state.user.faxes = [...this.state.user.faxes, ""];
    } else if (type == "email") {
      this.state.user.emails = [...this.state.user.emails, ""];
    } else if (type == "phone") {
      this.state.user.phones = [...this.state.user.phones, ""];
    }

    this.setState({
      user: this.state.user,
    });
  }

  removeFields(type, index, value) {
    this.state.user[type] = this.state.user[type].filter(
      (item) => item !== value
    );

    this.setState({
      user: this.state.user,
    });
  }

  handleUserDataChange = (type, value, index) => {
    if (type == "faxes" || type == "emails" || type == "phones") {
      this.state.user[type][index] = value;
    } else {
      this.state.user[type] = value;
    }

    console.log("this is user", this.state.user);
    this.setState({
      user: this.state.user,
    });
  };

  updateProfile = (e) => {
    console.log("updateProfile Clicked!");
    e.preventDefault();
    const form = this.props.form;
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log("check !err", !err);
      console.log("check error values", values);
      if (this.state.user.emails.length == 0) {
        message.error("Please enter Emails");
      } else if (this.state.user.phones.length == 0) {
        message.error("Please enter Phones");
      } else if (!err) {
        let outputJson = {
          user: this.state.user,
        };
        console.log("This is the outputJson", outputJson);

        let url = "./api/v1/users/" + this.state.user.id + "/update_profile";
        var that = this;

        API.patch(url, outputJson)
          .then(function (response) {
            console.log(response, "------------update res");
            if (response.data.message == "success") {
              that.setState({
                isEditMode: false,
              });
              // that.apiFetch(that.state.selectedData.id);
              message.success("Profile was successfully updated.");
            } else {
              message.error(response.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
            message.error("Profile failed to update. Please try again.");
          })
          .then(function () {
            // always executed
          });
      }
    });
  };

  searchCity = (country, term) => {
    Api.get('/api/v1/cities', { params: {country: country, term: term} })
    .then((response) => {
      this.setState({
        cities: response.data.data.map(city => city.attributes.name)
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleCityChange = debounce(value => {
    this.searchCity(this.state.country, value)
  }, 500)

  render() {
    const { getFieldDecorator } = this.props.form;
    const { cities, isEditMode } = this.state
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 } },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 } },
    };
    const menu = (
      <Menu>
        <Menu.Item disabled={true}>Credit: {this.state.user.credits}</Menu.Item>
        <Menu.Divider></Menu.Divider>
        <Menu.Item>
          <Link to="/bookings/series">My Bookings</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/bookings/due_payments">Due Payments</Link>
        </Menu.Item>
        {homepage_new.suka === "false" ? (
          <Menu.Item>
            <Link to="/users/credit_usage">Credit Usage</Link>
          </Menu.Item>
        ) : null}
        <Menu.Item>
          <a href={homepage_new.pdpa} target="_blank">
            PDPA
          </a>
        </Menu.Item>
        <Menu.Item>
          <Link to="/users/profile">
            <b>Edit Profile</b>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/agency/profile">
            Edit Agency Profile
          </Link>
        </Menu.Item>
        {homepage_new.suka === "false" ? (
          <Menu.Item>
            <a href={homepage_new.memo} target="_blank">
              Memo
            </a>
          </Menu.Item>
        ) : null}
        <Menu.Item>
          <a href="./user/edit">Change Password</a>
        </Menu.Item>
        <Menu.Item><Link to ="/subagents">Subagents</Link></Menu.Item>
        <Menu.Item>
          <a href="./users/sign_out">Logout</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div id="profile">
        <div className="user">
          <div className="logo_top">
            <Link to="/">
              <div className="logo_warp">
                <img src={homepage_new.app_logo} alt="ice" />
                <span>
                  {homepage_new.suka === "false" ? homepage_new.app_name : ""}
                </span>
              </div>
            </Link>
          </div>
          <div className="btn_user">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Link to="/notifications">
                  <Badge count={this.state.unread_notifications_count}>
                    <Icon type="bell" theme="twoTone" className="bell-icon" />
                  </Badge>
                </Link>
              </div>
              <Divider type="vertical" />
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="drop_menu"
              >
                <a className="ant-dropdown-link" href="#">
                  {this.state.user.username} <Icon type="down" />
                </a>
              </Dropdown>
            </div>
          </div>
          <div className="clear"></div>
        </div>

        <div className="content_user">
          <div className="content_warp">
            <h2>Edit Profile</h2>
            <Card>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <div className="input_warp">
                  <div className="formItemWarp50">
                    <Form.Item label="Username">
                      {getFieldDecorator("Username", {
                        initialValue: this.state.user.username,
                      })(<Input placeholder="Username" disabled={true} />)}
                    </Form.Item>
                  </div>
                  <div className="formItemWarp50">
                    <Form.Item label="Full name">
                      {getFieldDecorator("Full_name", {
                        initialValue: this.state.user.full_name,
                      })(<Input placeholder="Full Name" disabled={true} />)}
                    </Form.Item>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="input_warp">
                  <div className="formItemWarp50">
                    <Form.Item label="Website">
                      {getFieldDecorator("Website", {
                        initialValue: this.state.user.website,
                      })(
                        <Input
                          placeholder="Website"
                          disabled={!this.state.isEditMode}
                          onChange={(e) =>
                            this.handleUserDataChange(
                              "website",
                              e.target.value,
                              0
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  {this.state.isEditMode ? (
                    <div className="formItemWarp50">
                      {this.state.user.faxes != undefined &&
                      this.state.user.faxes.length > 0 ? (
                        this.state.user.faxes.map((fax, index) => {
                          return (
                            <Form.Item
                              key={index}
                              label={index === 0 ? "Faxes" : ""}
                            >
                              {getFieldDecorator("fax" + index, {
                                initialValue: fax,
                              })(
                                <Input
                                  placeholder="Faxes"
                                  allowClear
                                  className="removable_input"
                                  onChange={(e) =>
                                    this.handleUserDataChange(
                                      "faxes",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              )}
                              <span
                                onClick={(e) =>
                                  this.removeFields("faxes", index, fax)
                                }
                              >
                                <FontAwesomeIcon
                                  icon="times"
                                  className="remove_icon"
                                />
                              </span>
                            </Form.Item>
                          );
                        })
                      ) : (
                        <Form.Item
                          label="Faxes"
                          className="empty_form"
                        ></Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          type="dashed"
                          style={{ width: "100%" }}
                          onClick={(e) => this.addFields("fax")}
                        >
                          Add fax
                        </Button>
                      </Form.Item>
                    </div>
                  ) : this.state.user.faxes != undefined &&
                    this.state.user.faxes.length > 0 ? (
                    <div className="formItemWarp50">
                      <Form.Item label="Faxes">
                        {this.state.user.faxes.map((fax, index) => {
                          return (
                            <Tag key={index} color="orange">
                              {fax}
                            </Tag>
                          );
                        })}
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="formItemWarp50">
                      <Form.Item label="Faxes"></Form.Item>
                    </div>
                  )}
                  <div className="clear"></div>
                </div>
                <div className="input_warp">
                  {this.state.isEditMode ? (
                    <div className="formItemWarp50">
                      {this.state.user.emails != undefined &&
                      this.state.user.emails.length > 0 ? (
                        this.state.user.emails.map((email, index) => {
                          return (
                            <Form.Item
                              key={index}
                              label={index === 0 ? "Emails" : ""}
                            >
                              {getFieldDecorator("email" + index, {
                                initialValue: email,
                                rules: [
                                  {
                                    type: "email",
                                    message: "The input is not valid Email",
                                  },
                                  {
                                    required: true,
                                    message: "Please input your Emails!",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="Emails"
                                  allowClear
                                  className="removable_input"
                                  onChange={(e) =>
                                    this.handleUserDataChange(
                                      "emails",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              )}
                              <span
                                onClick={(e) =>
                                  this.removeFields("emails", index, email)
                                }
                              >
                                <FontAwesomeIcon
                                  icon="times"
                                  className="remove_icon"
                                />
                              </span>
                            </Form.Item>
                          );
                        })
                      ) : (
                        <Form.Item
                          label="Emails"
                          className="empty_form"
                          required
                        ></Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          type="dashed"
                          style={{ width: "100%" }}
                          onClick={(e) => this.addFields("email")}
                        >
                          Add email
                        </Button>
                      </Form.Item>
                    </div>
                  ) : this.state.user.emails != undefined &&
                    this.state.user.emails.length > 0 ? (
                    <div className="formItemWarp50">
                      <Form.Item label="Emails" required>
                        {this.state.user.emails.map((email, index) => {
                          return (
                            <Tag key={index} color="orange">
                              {email}
                            </Tag>
                          );
                        })}
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="formItemWarp50">
                      <Form.Item label="Emails" required></Form.Item>
                    </div>
                  )}
                  {this.state.isEditMode ? (
                    <div className="formItemWarp50">
                      {this.state.user.phones != undefined &&
                      this.state.user.phones.length > 0 ? (
                        this.state.user.phones.map((phone, index) => {
                          return (
                            <Form.Item
                              key={index}
                              label={index === 0 ? "Phones" : ""}
                            >
                              {getFieldDecorator("phone" + index, {
                                initialValue: phone,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please input your Phones!",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="Phones"
                                  allowClear
                                  className="removable_input"
                                  onChange={(e) =>
                                    this.handleUserDataChange(
                                      "phones",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              )}
                              <span
                                onClick={(e) =>
                                  this.removeFields("phones", index, phone)
                                }
                              >
                                <FontAwesomeIcon
                                  icon="times"
                                  className="remove_icon"
                                />
                              </span>
                            </Form.Item>
                          );
                        })
                      ) : (
                        <Form.Item
                          label="Phones"
                          className="empty_form"
                          required
                        ></Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          type="dashed"
                          style={{ width: "100%" }}
                          onClick={(e) => this.addFields("phone")}
                        >
                          Add phone
                        </Button>
                      </Form.Item>
                    </div>
                  ) : this.state.user.phones != undefined &&
                    this.state.user.phones.length > 0 ? (
                    <div className="formItemWarp50">
                      <Form.Item label="Phones" required>
                        {this.state.user.phones.map((phone, index) => {
                          return (
                            <Tag key={index} color="orange">
                              {phone}
                            </Tag>
                          );
                        })}
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="formItemWarp50">
                      <Form.Item label="Phones" required></Form.Item>
                    </div>
                  )}
                  <div className="clear"></div>
                </div>
                <div className="input_warp">
                  <div className="formItemWarp100">
                    <Form.Item label="Address">
                      {getFieldDecorator("Address", {
                        initialValue: this.state.user.address,
                        rules: [
                          {
                            required: true,
                            message: "Please input your Address!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Address"
                          disabled={!this.state.isEditMode}
                          onChange={(e) =>
                            this.handleUserDataChange(
                              "address",
                              e.target.value,
                              0
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="input_warp">
                  <div className="formItemWarp50">
                    <Form.Item label="City">
                      {getFieldDecorator("City", {
                        initialValue: this.state.user.city,
                        rules: [
                          {
                            required: true,
                            message: "Please input your City!",
                          },
                        ],
                      })(
                        this.state.isEditMode ? 
                        <AutoComplete
                          dataSource={cities}
                          onChange={value => this.handleCityChange(value)}
                        >
                          <Input
                            placeholder="City"
                            disabled={!this.state.isEditMode}
                            onChange={(e) =>
                              this.handleUserDataChange("city", e.target.value, 0)
                            }
                          />
                        </AutoComplete>
                        :
                        <Input
                          placeholder="City"
                          disabled={!this.state.isEditMode}
                          onChange={(e) =>
                            this.handleUserDataChange("city", e.target.value, 0)
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div className="formItemWarp50">
                    <Form.Item label="Postal">
                      {getFieldDecorator("Postal", {
                        initialValue: this.state.user.postal,
                        rules: [
                          {
                            required: true,
                            message: "Please input your Postal!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Postal"
                          disabled={!this.state.isEditMode}
                          onChange={(e) =>
                            this.handleUserDataChange(
                              "postal",
                              e.target.value,
                              0
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </div>

                  <div className="clear"></div>
                </div>
                <div className="input_warp">
                  <div className="formItemWarp50">
                    <Form.Item label="State">
                      {getFieldDecorator("State", {
                        initialValue: this.state.user.state,
                      })(<Input placeholder="State" disabled={true} />)}
                    </Form.Item>
                  </div>
                  <div className="formItemWarp50">
                    <Form.Item label="Country">
                      {getFieldDecorator("Country", {
                        initialValue: this.state.user.country,
                      })(<Input placeholder="Country" disabled={true} />)}
                    </Form.Item>
                  </div>
                  <div className="clear"></div>
                </div>

                <div className="input_warp btn_select">
                  {!this.state.isEditMode ? (
                    <div className="btn_warp">
                      <Button
                        type="primary"
                        onClick={() => {
                          this.isEditMode();
                        }}
                      >
                        EDIT
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.isEditMode ? (
                    <div className="btn_warp">
                      <Button type="primary" onClick={this.updateProfile}>
                        SAVE
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.isEditMode ? (
                    <div className="btn_warp">
                      <Button
                        type="dashed"
                        className="cancel"
                        onClick={() => {
                          this.isEditMode();
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="clear"></div>
              </Form>
            </Card>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

const ProfilePage = Form.create({ name: "Profile" })(Profile);
export default withRouter(ProfilePage);
