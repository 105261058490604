import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import {
  Tag,
  Typography,
  Modal,
  Dropdown,
  Button,
  Menu,
  Form,
  Input,
  Select,
  DatePicker,
  Icon,
  message,
  Tooltip,
  Col,
  Row,
  Statistic,
  Card,
  Skeleton,
  Spin,
  Empty,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import MenuBooking from "../menu";
import API from "../../../../api";
import "./index.css";
import moment from "moment";
import jsonToFormData from "json-form-data";
import qs from "qs";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { OrderDetailsTable } from "./OrderDetailsTable";
import { OrderTable } from "./OrderTable";
import CopyToClipboardTooltip from "../../../copyToClipboardTooltip";
import DownloadPdfModal from "./DownloadPdfModal";

const { Paragraph } = Typography;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

function onChange(value) {
  console.log(`selected ${value}`);
}

function onChangeDate(date, dateString) {
  console.log(date, dateString);
}
function beforeUpload(file) {
  const isJpgOrPngOrPdf =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "application/pdf";
  if (!isJpgOrPngOrPdf) {
    message.error("You can only upload JPG/PNG/PDF file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("File must smaller than 2MB!");
  }
  return false;
  // return isJpgOrPng && isLt2M;
}

class TourBooking extends Component {
  constructor(props) {
    super(props);

    const series_params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      data: [],
      loading: true,
      moadlVisible: false,
      confirmInsuranceModalVisible: false,
      confirmInsuranceModalConfirmButtonDisable: false,
      viewOrderModalVisible: false,
      confirmDirty: false,
      autoCompleteResult: [],
      selectValueNational: 1,
      toggle: false,
      noteClick: true,
      selectedData: "",
      total_pax: 0,
      total_records: 0,
      fileList: [],
      formDataState: null,
      isEditMode: false,
      insuranceModalClosable: true,
      insuranceModalLoading: false,
      sorting_types: [],
      payment_statuses: [],
      book_date_from:
        series_params["book_date_from"] != undefined
          ? series_params["book_date_from"]
          : "",
      book_date_to:
        series_params["book_date_to"] != undefined
          ? series_params["book_date_to"]
          : "",
      dept_date_from:
        series_params["dept_date_from"] != undefined
          ? series_params["dept_date_from"]
          : "",
      dept_date_to:
        series_params["dept_date_to"] != undefined
          ? series_params["dept_date_to"]
          : "",
      name: series_params["name"] != undefined ? series_params["name"] : "",
      order_no:
        series_params["order_no"] != undefined ? series_params["order_no"] : "",
      ref_no:
        series_params["ref_no"] != undefined ? series_params["ref_no"] : "",
      tour_code:
        series_params["tour_code"] != undefined
          ? series_params["tour_code"]
          : "",
      sort_type:
        series_params["sort_type"] != undefined
          ? series_params["sort_type"]
          : "",
      destination:
        series_params["destination"] != undefined
          ? series_params["destination"]
          : "",
      payment_status:
        series_params["payment_status"] != undefined
          ? series_params["payment_status"]
          : "",
      page: series_params["page"] != undefined ? series_params["page"] : "",
      pagination: {
        current: 1,
        pageSize: 20,
      },
      itineraryModalVisible: false,
      itineraryModalContent: "",
      itineraryModalCopyUrl: "",
      numPages: null,
      page_width: 1000,
      bookingFormEnabled: false,
      countries: [],
    };

    this.submitForm = this.submitForm.bind(this);
  }

  toSnakeCase(str) {
    return (
      str &&
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.toLowerCase())
        .join("_")
    );
  }

  onDocumentLoadSuccess = async (pdf_doc) => {
    const page = await pdf_doc.getPage(1);
    console.log(page.view[2]); // Width
    console.log(page.view[3]); // Height
    this.setState({
      numPages: pdf_doc["_pdfInfo"]["numPages"],
      page_width: page.view[2] + 50,
    });
  };

  handleOrderNo(order_no) {
    let order = order_no;
    let order_substr = order_no.substr(-1);
    if (order_substr == "T" || order_substr == "I") {
      order = order_no.slice(0, -1);
    }
    return order;
  }

  /************************************* componentDidMount ***************************************/
  componentDidMount() {
    const { pagination } = this.state;

    this.sortTypeFetch();
    this.paymentStatusFetch();
    this.fetchCountries();
    this.fetch({ pagination });
  }

  /************************************* apiFetch ***************************************/
  apiFetch = (selected_data_id) => {
    let outputJson = {
      book_date_from: this.state.book_date_from,
      book_date_to: this.state.book_date_to,
      dept_date_from: this.state.dept_date_from,
      dept_date_to: this.state.dept_date_to,
      name: this.state.name,
      tour_code: this.state.tour_code,
      ref_no: this.state.ref_no,
      sort_type: this.state.sort_type,
      destination: this.state.destination,
      payment_status: this.state.payment_status,
      booking_group_id: selected_data_id,
    };

    let url = "./api/v1/series/bookings?" + qs.stringify(outputJson);
    var that = this;
    API.get(url)
      .then(function (response) {
        console.log(response, "------------res");
        let selected_data = response.data.data_items.find(
          (x) => x.id === selected_data_id
        );

        that.setState({
          data: response.data.data_items,
          selectedData: selected_data === undefined ? "" : selected_data,
          total_pax: response.data.total_pax,
          total_records: response.data.total_records,
          bookingFormEnabled: response.data.booking_form_enabled,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  fetchCountries = () => {
    let that = this
    API.get('./api/v1/countries')
    .then(function (response) {
      that.setState({
        countries: response.data.data.map(country => country.attributes.name),
      })
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  sortTypeFetch() {
    let url = "./api/v1/series/listing_sort";
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        console.log(response, "------------res");
        that.setState({
          sorting_types: response.data,
          // loading: false
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  paymentStatusFetch() {
    let url = "./api/v1/series/payment_status_filter";
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        console.log(response, "------------res");
        that.setState({
          payment_statuses: response.data.payment_statuses,
          // loading: false
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  tourDetailsFetch(id) {
    let url = "./api/v1/series/bookings/" + id;
    var that = this;
    API.get(url)
      .then(function (response) {
        console.log(response.data, "------------res data");
        that.setState({
          selectedData: response.data,
          loading: false,
          viewOrderModalVisible: true,
        });

        
        that.state.selectedData.bookings.forEach((booking) => {
          const bookingFieldsToSet = {
            [`surname${booking.id}`]: booking.surname,
            [`given_name${booking.id}`]: booking.given_name,
            [`designation${booking.id}`]: booking.designation,
            [`date_birth${booking.id}`]: booking.date_of_birth ?? null,
            [`Mobile${booking.id}`]: booking.mobile,
            [`passport_number${booking.id}`]: booking.passport_number,
            [`Passport_expiry_date${booking.id}`]: booking.passport_expiry_date ?? null,
            [`passport_photocopy_image${booking.id}`] : booking.passport_photocopy_image,
            [`remark${booking.id}`]: booking.remark,
            [`operator_remark${booking.id}`]: booking.operator_remark
          };
          
          booking.is_malaysian = booking.nric != '' ? true : false//Todo: temp fixed, until next task nationality being added

          that.props.form.setFieldsValue(bookingFieldsToSet);

          booking.infant_bookings_attributes.forEach((infant_booking, index) => {
            const infantFieldsToSet = {
              [`${booking.id}surname${index}`]: infant_booking.surname,
              [`${booking.id}given_name${index}`]: infant_booking.given_name,
              [`${booking.id}date_birth${index}`]: infant_booking.date_of_birth,
              [`${booking.id}passport_number${index}`]: infant_booking.passport_number,
              [`${booking.id}Passport_expiry_date${index}`]: infant_booking.passport_expiry_date,
              [`${booking.id}remark${index}`]: infant_booking.remark,
              [`${booking.id}passport_photocopy_image${index}`]: infant_booking.passport_photocopy_image,
              
              [`${booking.id}guardian_name${infant_booking.id}${index}`]: infant_booking.ph_name,
              [`${booking.id}Guardian_dob${infant_booking.id}${index}`]: infant_booking.ph_dob,
              [`${booking.id}guardian_designation${infant_booking.id}${index}`]: infant_booking.ph_designation,
              [`${booking.id}guardian_id_no${infant_booking.id}${index}`]: infant_booking.ph_id_number,
              [`guardian${infant_booking.id}`]: infant_booking.parent_id,
            };
            
            that.props.form.setFieldsValue(infantFieldsToSet);
            infant_booking.is_malaysian = infant_booking.nric != '' ? true : false //Todo: temp fixed, until next task nationality being added
          });
        });

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      pagination,
      // sortField: sorter.field,
      // sortOrder: sorter.order,
      // ...filters,
    });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    let outputJson = {
      book_date_from: this.state.book_date_from,
      book_date_to: this.state.book_date_to,
      dept_date_from: this.state.dept_date_from,
      dept_date_to: this.state.dept_date_to,
      name: this.state.name,
      tour_code: this.state.tour_code,
      ref_no: this.state.ref_no,
      sort_type: this.state.sort_type,
      destination: this.state.destination,
      payment_status: this.state.payment_status,
      page: params.pagination.current,
    };

    this.props.history.push({
      pathname: "/bookings/series",
      search: "?" + qs.stringify(outputJson),
    });

    let url = "./api/v1/series/bookings?" + qs.stringify(outputJson);
    var that = this;
    API.get(url)
      .then(function (response) {
        console.log(response.data, "------------res data");
        that.setState({
          data: response.data.data_items,
          total_pax: response.data.total_pax,
          total_records: response.data.total_records,
          bookingFormEnabled: response.data.booking_form_enabled,
          loading: false,
          pagination: {
            ...params.pagination,
            total:
              response.data.data_items.length > 0
                ? response.data.total_records
                : 0,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  /************************************* handleSubmit ***************************************/
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  /************************************* handleConfirmBlur ***************************************/
  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleBookingGroupDataChange = (type, value) => {
    this.state.selectedData[type] = value;
    console.log("this is selected data", this.state.selectedData);
    this.setState({
      selectedData: this.state.selectedData,
    });
  };
  handleBookingDataChange = (index, type, value) => {
    console.log(index, "index");

    this.state.selectedData.bookings[index][type] = value;
    /** 13 June 2024 commented out by Stefan, to be reopened once RSVN ready */
    /** ===================================================== */
    // if(type == 'is_malaysian' && value == false){
    //   this.state.selectedData.bookings[index]['nric'] = ''
    // }
    /** ===================================================== */

    console.log("this is selected data", this.state.selectedData);
    this.setState({
      selectedData: this.state.selectedData,
    });
  };
  handleInfantDataChange = (booking_index, index, type, value) => {
    console.log(index, "index");
    this.state.selectedData.bookings[booking_index][
      "infant_bookings_attributes"
    ][index][type] = value;

    /** 13 June 2024 commented out by Stefan, to be reopened once RSVN ready */
    /** ===================================================== */
    if (type == "is_malaysian" && value == false) {
      this.state.selectedData.bookings[booking_index][
        "infant_bookings_attributes"
      ][index]["nric"] = "";
    }
    /** ===================================================== */

    console.log("this is selected data", this.state.selectedData);
    this.setState({
      selectedData: this.state.selectedData,
    });
  };
  handleBookingInsuranceDataChange = (index, nominee_index, type, value) => {
    console.log(index, nominee_index, "index, nominee_index");
    this.state.selectedData.bookings[index]["insurance_nomination"][
      nominee_index
    ][type] = value;
    console.log("this is selected data", this.state.selectedData);
    this.setState({
      selectedData: this.state.selectedData,
    });
  };
  handleInfantInsuranceDataChange = (
    index,
    infant_index,
    nominee_index,
    type,
    value
  ) => {
    console.log(index, nominee_index, "index, nominee_index");
    this.state.selectedData.bookings[index]["infant_bookings_attributes"][
      infant_index
    ]["insurance_nomination"][nominee_index][type] = value;
    console.log("this is selected data", this.state.selectedData);
    this.setState({
      selectedData: this.state.selectedData,
    });
  };
  handleBookingUploadDataChange = (index, type, value) => {
    console.log(index, "index");
    // console.log(value, 'handleBookingUploadDataChange -- value');
    this.state.selectedData.bookings[index][type] = value;
    console.log("this is selected data", this.state.selectedData);
    this.setState({
      selectedData: this.state.selectedData,
    });
  };
  handleInfantUploadDataChange = (booking_index, index, type, value) => {
    console.log(index, "index");
    // console.log(value, 'handleBookingUploadDataChange -- value');
    this.state.selectedData.bookings[booking_index][
      "infant_bookings_attributes"
    ][index][type] = value;
    console.log("this is selected data", this.state.selectedData);
    this.setState({
      selectedData: this.state.selectedData,
    });
  };

  /************************************* handleOk ***************************************/
  handleOk = (e, type) => {
    e.preventDefault();
    console.log("Submit modal --> ", type);

    if (type == "confirm_insurance_modal") {
      let url =
        "./api/v1/series/" +
        this.state.selectedData.id +
        "/create_insurance_export";
      var that = this;

      that.setState({
        confirmInsuranceModalConfirmButtonDisable: true,
        insuranceModalClosable: false,
        insuranceModalLoading: true,
      });

      API.post(url)
        .then(function (response) {
          console.log(response, "------------res");
          that.tourDetailsFetch(that.state.selectedData.id);
          that.setState({
            confirmInsuranceModalVisible: false,
            confirmInsuranceModalConfirmButtonDisable: false,
            insuranceModalClosable: true,
            insuranceModalLoading: false,
          });
          if (response.data.message == "success") {
            message.success("Insurance Confirmed");
          } else {
            message.error("Error");
          }
        })
        .catch(function (error) {
          console.log(error);
          that.apiFetch(that.state.selectedData.id);
          that.setState({
            insuranceModalClosable: true,
            confirmInsuranceModalConfirmButtonDisable: false,
            insuranceModalLoading: false,
            // data: response.data
          });
          message.error("Error");
        })
        .then(function () {
          // always executed
        });
    }
  };

  /************************************* handleCancel ***************************************/
  handleCancel = (e, type) => {
    console.log("Close modal --> ", type);

    // this.setState({
    //   selectedData: ''
    // });
    // if (type == 'edit_modal') {
    //   this.setState({
    //     moadlVisible: false,
    //   });
    // } else
    if (type == "confirm_insurance_modal") {
      this.setState({
        confirmInsuranceModalVisible: false,
      });
    } else if (type == "view_order_modal") {
      this.setState({
        viewOrderModalVisible: false,
        isEditMode: false,
      });
    } else if (type == "itinerary_modal") {
      this.setState({
        itineraryModalVisible: false,
        itineraryModalContent: "",
      });
    }
  };

  /************************************* onChangeSelectNational ***************************************/
  onChangeSelectNational = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      selectValueNational: e.target.value,
    });
  };

  /************************************* normFile ***************************************/
  // normFile = e => {
  //   console.log('Upload event:', e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  normFile = (info) => {
    console.log("Upload event:", info.fileList);
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    return fileList;
  };

  /************************************* showModal ***************************************/
  showModal = (data, type) => {
    if (type != "itinerary_modal") {
      this.setState({
        selectedData: data,
      });
    }
    // if (type == 'edit_modal') {
    //   this.setState({
    //     moadlVisible: true,
    //     viewOrderModalVisible: false,
    //   });
    // } else
    if (type == "confirm_insurance_modal") {
      this.setState({
        confirmInsuranceModalVisible: true,
      });
    } else if (type == "view_order_modal") {
      this.tourDetailsFetch(data.id);
    } else if (type == "itinerary_modal") {
      const { fileUrl, fileCopyUrl } = data
      this.setState({
        itineraryModalVisible: true,
        itineraryModalContent: fileUrl,
        itineraryModalCopyUrl: fileCopyUrl,
      });
    }
  };

  /************************************* isEditMode ***************************************/
  isEditMode = () => {
    this.setState((prevState) => ({
      isEditMode: !prevState.isEditMode,
    }));
  };

  updateBooking = (e) => {
    console.log("Clicked!");
    e.preventDefault();
    const form = this.props.form;
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log("check !err", !err);
      console.log("check error values", values);
      if (!err) {
        // console.log('Received values of form: ', values);
        let outputJson = {
          booking_group: {
            insurance_receiver: this.state.selectedData.insurance_receiver,
            bookings_attributes: this.state.selectedData.bookings,
          },
        };
        console.log("This is the outputJson", outputJson);
        var formData = jsonToFormData(outputJson);

        let url =
          "./api/v1/series/" +
          this.state.selectedData.id +
          "/bookings/" +
          this.state.selectedData.id;
        var that = this;

        API.patch(url, formData)
          .then(function (response) {
            console.log(response, "------------res");
            that.setState({
              loading: false,
            });
            if (response.data.message == "success") {
              that.setState({
                isEditMode: false,
              });
              that.tourDetailsFetch(that.state.selectedData.id);
              message.success("Updated");
            } else {
              message.error(response.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
            that.setState({
              loading: false,
              // data: response.data
            });
            message.error("Error while updating...");
          })
          .then(function () {
            // always executed
          });
      }
    });
  };

  sendConfirmation = (e) => {
    e.preventDefault();

    let url = "./api/v1/series/" + this.state.selectedData.id + "/confirmation";
    var that = this;

    API.get(url)
      .then(function (response) {
        console.log(response, "------------res");
        that.setState({
          loading: false,
        });
        if (response.data.message == "success") {
          that.apiFetch();
          message.success("Confirmation email sent!");
        } else {
          message.error(response.data.error);
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error while sending confirmation email...");
      })
      .then(function () {
        // always executed
      });
  };

  /************************************* onChangeClickBox ***************************************/
  onChangeClickBox(e) {
    let isChecked = e.target.checked === true;
    console.log(`checked = ${isChecked}`);
    this.setState({
      noteClick: isChecked,
    });
  }

  handleSearchChange = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleButtonPress = () => {
    this.submitForm();
  };

  /****************************** submitForm *******************************/
  submitForm(event) {
    // event.preventDefault();
    this.setState({
      loading: true,
    });

    let outputJson = {
      book_date_from: this.state.book_date_from,
      book_date_to: this.state.book_date_to,
      dept_date_from: this.state.dept_date_from,
      dept_date_to: this.state.dept_date_to,
      name: this.state.name,
      tour_code: this.state.tour_code,
      ref_no: this.state.ref_no,
      sort_type: this.state.sort_type,
      destination: this.state.destination,
      payment_status: this.state.payment_status,
      page: this.state.pagination.current,
    };

    console.log("This is the outputJson", outputJson);
    console.log(qs.stringify(outputJson));
    console.log(this.props);

    this.props.history.push({
      pathname: "/bookings/series",
      search: "?" + qs.stringify(outputJson),
    });

    let url = "./api/v1/series/bookings?" + qs.stringify(outputJson);
    var that = this;
    API.get(url)
      .then(function (response) {

        console.log(response, "------------res");
        that.setState({
          data: response.data.data_items,
          total_pax: response.data.total_pax,
          total_records: response.data.total_records,
          bookingFormEnabled: response.data.booking_form_enabled,
          loading: false,
          pagination: {
            total: response.data.data_items.length > 0 ? response.data.total_records : 0,
          },
        });
      })
      .catch(function (error) {
        that.setState({
          loading: false,
          // data: response.data
        });
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  processSolutions(data) {
    const {
      book_date_from,
      book_date_to,
      dept_date_from,
      dept_date_to,
      order_no,
      sort_type,
      tour_code,
      name,
    } = this.state;
    console.log(sort_type, "======sort_type");

    if (data && data.length) {
      if (book_date_from !== "" && book_date_to !== "") {
        data = data.filter(
          (i) => i.book_date >= book_date_from && i.book_date <= book_date_to
        );
      } else if (book_date_from === "" && book_date_to !== "") {
        data = data.filter((i) => i.book_date <= book_date_to);
      } else if (book_date_from !== "" && book_date_to === "") {
        data = data.filter((i) => i.book_date >= book_date_from);
      }

      if (dept_date_from !== "" && dept_date_to !== "") {
        data = data.filter(
          (i) => i.departure >= dept_date_from && i.departure <= dept_date_to
        );
      } else if (dept_date_from === "" && dept_date_to !== "") {
        data = data.filter((i) => i.departure <= dept_date_to);
      } else if (dept_date_from !== "" && dept_date_to === "") {
        data = data.filter((i) => i.departure >= dept_date_from);
      }

      if (order_no !== "") {
        data = data.filter((i) => order_no.slice(0, -1) === i.order_no);
      }

      if (tour_code !== "") {
        data = data.filter((i) => tour_code === i.code);
      }

      if (name !== "") {
        data = data.filter((i) => name === i.agent_name);
      }

      // if (voucher_status !== "") {
      //   data = data.filter(i => voucher_status === i.voucher_status);
      // }

      if (sort_type == "order_no_asc") {
        return data.sort((a, b) =>
          a.order_no > b.order_no ? 1 : b.order_no > a.order_no ? -1 : 0
        );
      } else if (sort_type == "order_no_desc") {
        return data.sort((a, b) =>
          b.order_no > a.order_no ? 1 : a.order_no > b.order_no ? -1 : 0
        );
      } else if (sort_type == "name_asc") {
        return data.sort((a, b) =>
          a.agent_name > b.agent_name ? 1 : b.agent_name > a.agent_name ? -1 : 0
        );
      } else if (sort_type == "name_desc") {
        return data.sort((a, b) =>
          b.agent_name > a.agent_name ? 1 : a.agent_name > b.agent_name ? -1 : 0
        );
      } else if (sort_type == "booking_date_asc") {
        return data.sort(function (a, b) {
          var dateA = new Date(a.book_date),
            dateB = new Date(b.book_date);
          return dateA - dateB;
        });
      } else if (sort_type == "booking_date_desc") {
        return data.sort(function (a, b) {
          var dateA = new Date(a.book_date),
            dateB = new Date(b.book_date);
          return dateB - dateA;
        });
      } else if (sort_type == "departure_date_asc") {
        return data.sort(function (a, b) {
          var dateA = new Date(a.departure),
            dateB = new Date(b.departure);
          return dateA - dateB;
        });
      } else if (sort_type == "departure_date_desc") {
        return data.sort(function (a, b) {
          var dateA = new Date(a.departure),
            dateB = new Date(b.departure);
          return dateB - dateA;
        });
      } else if (sort_type == "tour_code_asc") {
        // return data.sort(function(a, b) {
        //     return (a.code === null) - (b.code === null) || +(a > b) || -(a < b);
        // });
        return data.sort((a, b) =>
          a.code > b.code ? 1 : b.code > a.code ? -1 : 0
        );
      } else if (sort_type == "tour_code_desc") {
        // return data.sort(function(a, b) {
        //     return (b.code === null) - (a.code === null) || +(b > a) || -(b < a);
        // });
        return data.sort((a, b) =>
          b.code > a.code ? 1 : a.code > b.code ? -1 : 0
        );
      } else if (sort_type == "destination_asc") {
        // return data.sort(function(a, b) {
        //     return (a.destination === null) - (b.destination === null) || +(a > b) || -(a < b);
        // });
        return data.sort((a, b) =>
          a.destination > b.destination
            ? 1
            : b.destination > a.destination
            ? -1
            : 0
        );
      } else if (sort_type == "destination_desc") {
        // return data.sort(function(a, b) {
        //     return (b.destination === null) - (a.destination === null) || +(b > a) || -(b < a);
        // });
        return data.sort((a, b) =>
          b.destination > a.destination
            ? 1
            : a.destination > b.destination
            ? -1
            : 0
        );
      }
    }
    return data;
  }

  render() {
    const {
      selectValueNational,
      noteClick,
      fileList,
      sorting_types,
      payment_statuses,
      pagination,
      loading,
      itineraryModalCopyUrl,
      bookingFormEnabled,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 } },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 } },
    };

    const columns = [
      {
        title: "Booking Date",
        width: 130,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "Ref No.",
        width: 150,
        dataIndex: "ref_no",
        key: "ref_no",
      },
      {
        title: "Name",
        width: 150,
        dataIndex: "agent_name",
        key: "agent_name",
      },
      {
        title: "Tourcode",
        width: 250,
        dataIndex: "code",
        key: "code",
        render: (text, record) => (
          <span>
            {record.code}&nbsp;
            {record.guaranteed_indicator === "" ? null : (
              <Tag color={"green"} key={0}>
                {record.guaranteed_indicator}
              </Tag>
            )}
            {record.booked_seats === null || record.booked_seats == 0  ? null : (
              <Tooltip
                autoAdjustOverflow={true}
                title={
                  'Group Size'
                }
              >
                <Tag color={"cyan"} key={0}>
                  {record.booked_seats} / {record.total_seats}
                </Tag>
              </Tooltip>
            )}
          </span>
        ),
      },
      {
        title: "Pax",
        width: 70,
        dataIndex: "total_quantity",
        key: "total_quantity",
      },
      {
        title: "Destination",
        width: 150,
        dataIndex: "destination",
        key: "destination",
      },
      {
        title: "Package Name",
        width: 150,
        dataIndex: "caption",
        key: "caption",
        width: "30%",
      },
      {
        title: "Itinerary",
        width: 80,
        dataIndex: "file_url",
        key: "file_url",
        render: (text, record) => (
          <span>
            <span
              onClick={() => this.showModal({fileUrl: record.file_url, fileCopyUrl: record.file_copy_url}, "itinerary_modal")}
              className="itinerary_text"
            >
              {" "}
              EN
            </span>{" "}
            |{" "}
            <span
              onClick={() =>
                this.showModal({ fileUrl: record.cn_file_url, fileCopyUrl: record.file_cn_copy_url }, "itinerary_modal")
              }
              className="itinerary_text"
            >
              {" "}
              CN{" "}
            </span>
          </span>
        ),
      },
      {
        title: "Booking Form",
        width: 150,
        dataIndex: "booking_form",
        key: "booking_form",
        hidden: !bookingFormEnabled,
        render: (text, record) => (
          record.pdf_file_url && <DownloadPdfModal url={record.pdf_file_url} />
        ),
      },
      // {
      //   title: 'Price',
      //   width: 150,
      //   dataIndex: 'total_price',
      //   key: 'total_price',
      // },
      // {
      //   title: 'Quantity',
      //   width: 150,
      //   dataIndex: 'total_quantity',
      //   key: 'total_quantity',
      // },
      // {
      //   title: 'Destination',
      //   width: 200,
      //   dataIndex: 'destination',
      //   key: 'destination',
      // },
      {
        title: "Status",
        key: "payment_status",
        dataIndex: "payment_status",
        width: 100,
        fixed: "right",
        sorter: (a, b) => {
          return a.payment_status.localeCompare(b.payment_status);
        },
        render: (payment_status) => (
          <span>
            <Tag
              color={
                payment_status == "Paid " || payment_status == "Paid (Due)"
                  ? "green"
                  : payment_status == "Partial Paid " ||
                    payment_status == "Partial Paid (Due)"
                  ? "blue"
                  : payment_status == "Awaiting"
                  ? "geekblue"
                  : payment_status == "Pending"
                  ? "orange"
                  : payment_status == "Failed"
                  ? "red"
                  : payment_status == "Refunded" ||
                    payment_status == "Cancelled"
                  ? ""
                  : ""
              }
              key={0}
            >
              {payment_status}
            </Tag>
          </span>
        ),
      },
      {
        title: "Action",
        width: 60,
        key: "action",
        fixed: "right",
        // dataIndex: 'code',
        render: (record) => (
          <span>
            <Button
              type="link"
              onClick={() => {
                this.showModal(record, "view_order_modal");
              }}
            >
              View
            </Button>
          </span>
        ),
      },
    ];

    const filteredColumns = columns.filter(col => !col.hidden);

    const columns_order_information = [
      {
        title: "Name",
        width: 150,
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Category",
        width: 150,
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Remark",
        width: 270,
        dataIndex: "remark",
        key: "remark",
        render: (remark) => (
          <Paragraph ellipsis={{ rows: 3, expandable: true }}>
            {remark}
          </Paragraph>
        ),
      },
    ];

    return (
      <div
        id="TourContainer"
        className={homepage_new.suka === "false" ? "" : "suka"}
      >
        <MenuBooking selected="series" />
        <div className="content">
          <p className="title">Series Bookings</p>

          <div>
            <Form {...formItemLayout} onSubmit={this.submitSearch}>
              <React.Fragment>
                <div className="input_warp">
                  {/*************************** Book Date ***************************/}
                  <div className="formItemWarp20">
                    <Form.Item label="Book Date From">
                      <DatePicker
                        format={dateFormat}
                        className="voucher_datepicker"
                        placeholder={"From"}
                        defaultValue={
                          this.state.book_date_from != ""
                            ? moment(this.state.book_date_from)
                            : ""
                        }
                        defaultPickerValue={moment()}
                        onChange={(date, dateString) =>
                          this.handleSearchChange("book_date_from", dateString)
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="formItemWarp20">
                    <Form.Item label="Book Date To">
                      <DatePicker
                        format={dateFormat}
                        className="voucher_datepicker"
                        placeholder={"To"}
                        defaultValue={
                          this.state.book_date_to != ""
                            ? moment(this.state.book_date_to)
                            : ""
                        }
                        defaultPickerValue={moment()}
                        onChange={(date, dateString) =>
                          this.handleSearchChange("book_date_to", dateString)
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="formItemWarp20">
                    <Form.Item label="Departure Date From">
                      <DatePicker
                        format={dateFormat}
                        className="voucher_datepicker"
                        placeholder={"From"}
                        defaultValue={
                          this.state.dept_date_from != ""
                            ? moment(this.state.dept_date_from)
                            : ""
                        }
                        defaultPickerValue={moment()}
                        onChange={(date, dateString) =>
                          this.handleSearchChange("dept_date_from", dateString)
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="formItemWarp20">
                    <Form.Item label="Departure Date To">
                      <DatePicker
                        format={dateFormat}
                        className="voucher_datepicker"
                        placeholder={"To"}
                        defaultValue={
                          this.state.dept_date_to != ""
                            ? moment(this.state.dept_date_to)
                            : ""
                        }
                        defaultPickerValue={moment()}
                        onChange={(date, dateString) =>
                          this.handleSearchChange("dept_date_to", dateString)
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="formItemWarp20">
                    <Form.Item label="Name">
                      <Input
                        onChange={(e) =>
                          this.handleSearchChange("name", e.target.value)
                        }
                        defaultValue={this.state.name}
                      />
                    </Form.Item>
                  </div>
                  <div className="formItemWarp20">
                    <Form.Item label="Ref No.">
                      <Input
                        onChange={(e) =>
                          this.handleSearchChange("ref_no", e.target.value)
                        }
                        defaultValue={this.state.ref_no}
                      />
                    </Form.Item>
                  </div>
                  <div className="formItemWarp20">
                    <Form.Item label="Tour Code">
                      <Input
                        onChange={(e) =>
                          this.handleSearchChange("tour_code", e.target.value)
                        }
                        defaultValue={this.state.tour_code}
                      />
                    </Form.Item>
                  </div>
                  <div className="formItemWarp20">
                    <Form.Item label="Destination">
                      <Input
                        onChange={(e) =>
                          this.handleSearchChange("destination", e.target.value)
                        }
                        defaultValue={this.state.destination}
                      />
                    </Form.Item>
                  </div>
                  {/*<div className="formItemWarp20">
                        <Form.Item label="Voucher Status">
                          <Select
                            showSearch
                            placeholder="Select voucher status"
                            // optionFilterProp="children"
                            onChange={(value) => this.handleSearchChange('voucher_status', value)}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            defaultValue={this.state.voucher_status}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="Paid">Paid</Option>
                            <Option value="Failed">Failed</Option>
                            <Option value="Redeemed">Redeemed</Option>
                            <Option value="Expired">Expired</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="formItemWarp20">
                        <Form.Item label="Sort Type">
                          <Select placeholder="Select Sort Type" optionFilterProp="children"
                            onChange={(value) => this.handleSearchChange('sort_type', value)}
                            defaultValue={this.state.sort_type}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {
                              sorting_types && sorting_types.map((items, i) => {
                                return (
                                  <Option key={i} value={items}>{items}</Option>
                                )
                              })
                            }
                          </Select>
                        </Form.Item>
                      </div>*/}
                  <div className="formItemWarp20">
                    <Form.Item label="Payment Status">
                      <Select
                        placeholder="Select Sort Type"
                        optionFilterProp="children"
                        onChange={(value) =>
                          this.handleSearchChange("payment_status", value)
                        }
                        defaultValue={this.state.payment_status}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {payment_statuses &&
                          payment_statuses.map((items, i) => {
                            return (
                              <Option key={i} value={items}>
                                {items}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </React.Fragment>
              <div className="btn_submit">
                <Button
                  type="primary"
                  onClick={this.handleButtonPress}
                  icon="search"
                  size="default"
                  className="search_btn"
                >
                  Search
                </Button>
                <Button type="primary" className="space_btn">
                  &nbsp;
                </Button>
                <ReactToPrint
                  content={() => this.summaryRef}
                  documentTitle="print_summary"
                  pageStyle="
                      @page { size: A4 landscape; margin: 2px; padding: 0;}
                      body { zoom: 75%; margin-top: 10px}
                      .print_summary .ant-table-column-title{ text-align: left; }
                      .print_summary .ant-table-fixed th:last-child,
                      .print_summary .ant-table-fixed td:last-child, 
                      .ant-table-fixed-right,
                      .ant-pagination,
                      .ant-table-column-sorter { display: none; }
                      .ant-table-header-column { float: left; }
                      .ant-table-body{overflow-x: inherit;}
                      .ant-table-fixed {border-collapse: collapse; table-layout: auto; width: 100% !important}
                      .ant-table-fixed th, .ant-table-fixed td { border: 1px solid; padding: 1px; }
                    "
                >
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        type="primary"
                        icon="printer"
                        size="default"
                        className="print_btn"
                        onClick={handlePrint}
                      >
                        Print Summary
                      </Button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </Form>
            <div className="clear"></div>
            <div className="credit_statistic">
              <Row gutter={16}>
                <Col xs={12} sm={8}>
                  <Card>
                    <Statistic
                      title="Total Pax"
                      value={this.state.total_pax}
                      prefix={<Icon type="user" />}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
            <br />
            {this.state.data.length < 1 ? (
              <div className="empty">
                {this.state.loading ? (
                  <Skeleton active />
                ) : (
                  <Empty description="Booking not Found" />
                )}
              </div>
            ) : (
              <OrderTable
                ref={(el) => (this.summaryRef = el)}
                data={this.state.data}
                handleTableChange={this.handleTableChange}
                loading={loading}
                pagination={pagination}
                showModal={this.showModal}
                columns={filteredColumns}
              />
            )}
          </div>
        </div>

        {/************************************* Modal View  ***************************************/}
        <Modal
          visible={this.state.viewOrderModalVisible}
          onOk={(e) => this.handleOk(e, "view_order_modal")}
          onCancel={(e) => this.handleCancel(e, "view_order_modal")}
          className="bookingModal_sty seriesModal"
          footer={
           
              <span>
                {this.state.isEditMode ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      this.isEditMode();
                    }}
                  >
                    CANCEL
                  </Button>
                ) : (
                  ""
                )}
                {this.state.isEditMode ? (
                  <Button type="primary" onClick={this.updateBooking}>
                    SAVE
                  </Button>
                ) : (
                  ""
                )}
                {/*  
              !this.state.isEditMode ? 
                <Button type="primary" onClick={this.sendConfirmation}>CONFIRMATION</Button> : ''
            */}
                {!this.state.isEditMode && this.state.selectedData.editable ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      this.isEditMode();
                    }}
                  >
                    EDIT
                  </Button>
                ) : (
                  ""
                )}
                {!this.state.isEditMode ? (
                  <ReactToPrint
                    content={() => this.componentRef}
                    documentTitle="order_details"
                    pageStyle="
                  @page { size: A4 portrait;  margin: 2px; padding: 0;}
                  body{ zoom: 90%; margin-top: 0.1in;}
                  .ant-descriptions-view table, .flight table {border-collapse: collapse; width: 100%}
                  .ant-descriptions-view th, .ant-descriptions-view td, .flight th, .flight td{ border: 1px solid; padding: 1px;}
                  p.line { background-color: black; height: 3px; width:100%; padding: 0 5px;}
                  .input_warp {width: 100%; float: left;}
                  .formItemWarp50 {width: 50%; float: left;}
                  .formItemWarp25 {width: 25%; float: left;}
                  .ant-select-arrow, .anticon {display:none}
                  .ant-form-item-control input, .ant-select-selection{ background-color: #f5f5f5; width: 80%; border: 1px solid #d9d9d9; border-radius: 4px; color: rgba(0, 0, 0, 0.7); }
                  .ant-upload .image-display-photocopy{ width:100% !important; height:100% !important;}
                  .ant-radio-group { width: 100%; }
                  span.ant-radio { padding: 0 !important; margin: 0 !important;}
                  .ant-radio-group .ant-radio-wrapper{display: block; }
                  .table-header { display: block; zoom: 65%;}
                  .due_payment_footer { display: block; }
                "
                  >
                    <PrintContextConsumer>
                      {({ handlePrint }) => (
                        <Button type="primary" onClick={handlePrint}>
                          PRINT
                        </Button>
                      )}
                    </PrintContextConsumer>
                  </ReactToPrint>
                ) : (
                  ""
                )}
                {
                  !this.state.isEditMode && this.state.selectedData.booked_from != "iceb2b" ? (
                    <Dropdown
                      overlay={
                          <Menu>
                            <Menu.Item disabled={!this.state.selectedData.copy_payment_link_available}>
                              <CopyToClipboardTooltip text={window.location.hostname + this.state.selectedData.payment_link}>COPY PAYMENT LINK</CopyToClipboardTooltip>
                            </Menu.Item>
                            <Menu.Item disabled={
                              this.state.selectedData &&
                              this.state.selectedData.payable !== "Payable"
                            }>
                              <Link
                                to={{
                                  pathname:
                                    "/bookings/series/" +
                                    this.state.selectedData.id +
                                    "/payment",
                                }}
                              >
                                GO TO PAYMENT PAGE
                              </Link>
                            </Menu.Item>
                          </Menu>
                      }
                      disabled={
                        this.state.selectedData &&
                        this.state.selectedData.payable !== "Payable"
                      }
                    >
                      <Tooltip
                        autoAdjustOverflow={true}
                        title={
                          this.state.selectedData && this.state.selectedData.payable
                        }
                      >
                        <Button
                          type="primary"
                          disabled={
                            this.state.selectedData &&
                            this.state.selectedData.payable !== "Payable"
                          }
                        >
                         PAY NOW  <Icon type="down" />
                        </Button>
                      </Tooltip>
                    </Dropdown>
                  ) : (null)
                }
               
                {!this.state.isEditMode &&
                this.state.selectedData.booked_from != "iceb2b" &&
                this.state.selectedData.invoices !== undefined &&
                this.state.selectedData.invoices.length > 0 ? (
                  this.state.selectedData.invoices.length > 1 ? (
                    <Dropdown
                      overlay={
                        <Menu>
                          {this.state.selectedData.invoices.map(
                            (invoice, index) => {
                              return (
                                <Menu.Item key={index}>
                                  <a href={invoice.url} target="_blank">
                                    Invoice {index + 1}
                                  </a>
                                </Menu.Item>
                              );
                            }
                          )}
                        </Menu>
                      }
                    >
                      <Button type="primary">
                        DOWNLOAD INVOICES <Icon type="down" />
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button type="primary">
                      <a
                        href={this.state.selectedData.invoices[0].url}
                        target="_blank"
                      >
                        DOWNLOAD INVOICE
                      </a>
                    </Button>
                  )
                ) : (
                  ""
                )}
                {!this.state.isEditMode &&
                this.state.selectedData.booked_from != "iceb2b" &&
                this.state.selectedData.receipts !== undefined &&
                this.state.selectedData.receipts.length > 0 ? (
                  this.state.selectedData.receipts.length > 1 ? (
                    <Dropdown
                      overlay={
                        <Menu>
                          {this.state.selectedData.receipts.map(
                            (receipt, index) => {
                              return (
                                <Menu.Item key={index}>
                                  <a href={receipt.url} target="_blank">
                                    Receipt {index + 1}
                                  </a>
                                </Menu.Item>
                              );
                            }
                          )}
                        </Menu>
                      }
                    >
                      <Button type="primary">
                        DOWNLOAD RECEIPTS <Icon type="down" />
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button type="primary">
                      <a
                        href={this.state.selectedData.receipts[0].url}
                        target="_blank"
                      >
                        DOWNLOAD RECEIPT
                      </a>
                    </Button>
                  )
                ) : (
                  ""
                )}
                {!this.state.isEditMode &&
                this.state.selectedData.booked_from != "iceb2b" &&
                this.state.selectedData.credit_notes !== undefined &&
                this.state.selectedData.credit_notes.length > 0 ? (
                  this.state.selectedData.credit_notes.length > 1 ? (
                    <Dropdown
                      overlay={
                        <Menu>
                          {this.state.selectedData.credit_notes.map(
                            (credit_note, index) => {
                              return (
                                <Menu.Item key={index}>
                                  <a href={credit_note.url} target="_blank">
                                    Credit Note {index + 1}
                                  </a>
                                </Menu.Item>
                              );
                            }
                          )}
                        </Menu>
                      }
                    >
                      <Button type="primary">
                        DOWNLOAD CREDIT NOTES <Icon type="down" />
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button type="primary">
                      <a
                        href={this.state.selectedData.credit_notes[0].url}
                        target="_blank"
                      >
                        DOWNLOAD CREDIT NOTES
                      </a>
                    </Button>
                  )
                ) : (
                  ""
                )}
                {!this.state.isEditMode &&
                this.state.selectedData.agent_export_insurance ? (
                  <Button
                    type="primary"
                    onClick={() =>
                      this.showModal(
                        this.state.selectedData,
                        "confirm_insurance_modal"
                      )
                    }
                  >
                    CONFIRM INSURANCE
                  </Button>
                ) : (
                  ""
                )}
              </span>
            
          }
        >
          <OrderDetailsTable
            ref={(el) => (this.componentRef = el)}
            data={this.state.selectedData}
            edit_mode={this.state.isEditMode}
            formItemLayout={formItemLayout}
            handleSubmit={this.handleSubmit}
            countries={this.state.countries}
            form={this.props.form}
            handleBookingGroupDataChange={this.handleBookingGroupDataChange}
            normFile={this.normFile}
            beforeUpload={this.beforeUpload}
            handleBookingDataChange={this.handleBookingDataChange}
            handleInfantDataChange={this.handleInfantDataChange}
            handleInfantUploadDataChange={this.handleInfantUploadDataChange}
            handleInfantInsuranceDataChange={
              this.handleInfantInsuranceDataChange
            }
            handleBookingInsuranceDataChange={
              this.handleBookingInsuranceDataChange
            }
            handleBookingUploadDataChange={this.handleBookingUploadDataChange}
          />
        </Modal>

        {/************************************* Modal Confirm Insurance  ***************************************/}
        <Modal
          title="Chubb Travel Insurance Confirmation"
          visible={this.state.confirmInsuranceModalVisible}
          onOk={(e) => this.handleOk(e, "confirm_insurance_modal")}
          onCancel={(e) => this.handleCancel(e, "confirm_insurance_modal")}
          confirmLoading={this.state.insuranceModalLoading}
          className="seriesModal"
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            disabled:
              !(
                this.state.selectedData &&
                this.state.selectedData.confirm_export
              ) || this.state.confirmInsuranceModalConfirmButtonDisable,
          }}
          cancelButtonProps={{ disabled: !this.state.insuranceModalClosable }}
          closable={this.state.insuranceModalClosable}
          maskClosable={this.state.insuranceModalClosable}
        >
          {/************************************* Content ***************************************/}
          <div>
            <span>
              <h4>
                The following informations are <strong>required</strong> for
                Insurance Confirmation :
              </h4>
              <ol>
                <li>Designation</li>
                <li>Name</li>
                <li>D.O.B</li>
                <li>Mobile</li>
                <li>Passport Number</li>
                <li>Passport Expiry Date</li>
                <li>Upload Passport Copy</li>
                <li>Room Type</li>
              </ol>
              <p>
                The policy covers you twenty-four (24) hours prior to Your
                scheduled departure time in Malaysia provided You are in{" "}
                <strong class="insurance-disclaimer">direct transit</strong>{" "}
                between Your Home and the Overseas departure point in Malaysia
                or vice versa.
              </p>

              {this.state.selectedData &&
              this.state.selectedData.export_count_text != null &&
              this.state.selectedData.export_count_text !== "" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.selectedData.export_count_text.replace(
                      /\n/g,
                      "<br />"
                    ),
                  }}
                />
              ) : null}
            </span>
          </div>
        </Modal>

        <Modal
          title={
            <React.Fragment>
              <FontAwesomeIcon
                fixedWidth
                icon="clipboard-list"
                size="lg"
                className="file_icon"
              />
              &nbsp; Itinerary&nbsp;&nbsp;&nbsp;&nbsp;
              <a href={this.state.itineraryModalContent} target="_blank">
                <Button type="primary" icon="download">
                  Download
                </Button>
              </a>
              &nbsp;&nbsp;
              { 
                itineraryModalCopyUrl && <CopyToClipboardTooltip text={this.state.itineraryModalCopyUrl}><Button>Copy to clipboard</Button></CopyToClipboardTooltip>
              }
            </React.Fragment>
          }
          visible={this.state.itineraryModalVisible}
          width={this.state.page_width}
          maskClosable={false}
          onCancel={(e) => this.handleCancel(e, "itinerary_modal")}
          footer={[
            <Button
              type="primary"
              key="close"
              onClick={(e) => this.handleCancel(e, "itinerary_modal")}
            >
              Close
            </Button>,
          ]}
        >
          {this.state.itineraryModalContent == "" ? (
            <Spin />
          ) : (
            <Document
              file={this.state.itineraryModalContent}
              onLoadSuccess={this.onDocumentLoadSuccess}
              error={"There is no itinerary available."}
            >
              {Array.from(new Array(this.state.numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
        </Modal>
      </div>
    );
  }
}

const SeriesBooking = Form.create({ name: "Series" })(TourBooking);
export default withRouter(SeriesBooking);
